
import {
    GET_PRESCRIPTION,
    GET_TREATMENTPLAN,
    GET_PATIENT_FILES,
    GET_TELE_CALL_LOG,
    SET_FILTER_TIMELINE
} from './action';

const initialState = {
    prescriptionDetails: null,
    treatmentPlans: null,
    patientFiles: null,
    teleCallLogs: null,
    selectedFilter: 'All'
}

export default function timelineBoardReducer(state = initialState, action) {
    const { type, data } = action;
    switch (type) {
        case GET_PRESCRIPTION:
            return {
                ...state,
                prescriptionDetails: data,
                treatmentPlans: null,
                patientFiles: null,
                teleCallLogs: null,
            };
        case GET_TREATMENTPLAN:
            return {
                ...state,
                treatmentPlans: data,
                prescriptionDetails: null,
                patientFiles: null,
                teleCallLogs: null,
            };
        case GET_PATIENT_FILES:
            return {
                ...state,
                patientFiles: data,
                prescriptionDetails: null,
                treatmentPlans: null,
                teleCallLogs: null,
            };
        case GET_TELE_CALL_LOG:
            return {
                ...state,
                teleCallLogs: data,
                prescriptionDetails: null,
                treatmentPlans: null,
                patientFiles: null,
            };
        case SET_FILTER_TIMELINE:
            return {
                ...state,
                selectedFilter: data
            };
        default:
            return state;
    }
}