import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import InputComp from "../../../components/InputComp";
import ButtonComp from "../../../components/ButtonComp";

import { setSignupViaCodeAction, otpVerification } from '../../modules/Auth/Signup/action';
import { showSnackbar } from '../../modules/Snackbar/action';
import TimerComp from '../../../components/TimerComp';
import RegisterUserCont from './RegisterUserCont';


const useStyles = makeStyles((theme) => ({
    loginForm: {
        background: "#ffffff",
        minHeight: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

function VerifyOtpCont({ contactno }) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const [userCred, setUserCred] = useState({ contactno: contactno })
    const [isTimeLeft, SetTimeCheckup] = useState(false)
    const signupViaCode = useSelector(state => state.Patient.signup.signupViaCode)
    const otpVerificationData = useSelector(state => state.Patient.signup.otpVerificationData)

    const handleInputChange = e => {
        const { name, value } = e.target
        setUserCred({ ...userCred, [name]: value })
    }

    const handleClick = () => {
        if (userCred.contactno && userCred.contactno.length &&
            userCred.otp && userCred.otp.length) {
            dispatch(otpVerification(userCred));
        } else {
            return (
                dispatch(showSnackbar({
                    status: 0, message: "Please enter correct OTP"
                }))
            )
        }
    }

    const timesUpCheck = (timeLeft) => {
        SetTimeCheckup(timeLeft)
    }

    if (!signupViaCode || (isTimeLeft && !otpVerificationData)) {
        dispatch(setSignupViaCodeAction(null))
        return <Redirect to="/signup" />
    }
    if (otpVerificationData) {
        return <RegisterUserCont contactno={userCred.contactno} />
    }

    return (
        <div className="mt-3 container">
            <div className={`${classes.loginForm} row`}>
                <div className="col-10">
                    <h2 className="mb-3">Enter OTP</h2>
                    <p className="small">* You are almost there just enter
                    the sent OTP on your mobile no.
                    </p>
                    <div>
                        <InputComp
                            label="OTP *"
                            name="otp"
                            onChange={handleInputChange}
                            value={userCred.otp}
                            type="text"
                        />
                        {/* <InputComp
                            label="Password"
                            name="password"
                            type="password"
                            onChange={handleInputChange}
                            value={userCred.password}
                        />
                        <InputComp
                            label="Confirm Password"
                            name="rePass"
                            type="password"
                            onChange={handleInputChange}
                            value={userCred.rePass}
                        /> */}

                        {!isTimeLeft && <TimerComp currentTime={new Date()} timeType="m" numTime={6} timesUpCheck={timesUpCheck} />}

                        <ButtonComp
                            label="Submit"
                            onClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default VerifyOtpCont;
