
import {
    SNACKBAR_CLEAR,
    SNACKBAR_SUCCESS
} from './action';

const initialState = {
    snackbarOpen: false,
    errorSnackbarOpen: false,
    infoSnackbarOpen: false,
}

export default function snackReducer(state = initialState, action) {
    const { type, data } = action;
    switch (type) {
        case SNACKBAR_SUCCESS:
            return {
                ...state,
                snackbarOpen: true,
                snackbarMessage: data.message,
                snackbarStatus: data.status
            };
        case SNACKBAR_CLEAR:
            return {
                ...state,
                snackbarOpen: false,
                errorSnackbarOpen: false,
                infoSnackbarOpen: false
            };
        default:
            return state;
    }
};
