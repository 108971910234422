import React from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import VideocamIcon from "@material-ui/icons/Videocam";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";

import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { addCallDuration } from "../../Patient/modules/Socket/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  mainDialog: {
    background: "#efeeec",
    "& > div, & > div > div:nth-child(2), & > div > div:nth-child(2) > div": {
      width: "100%",
      height: "100%",
    },
  },
  timeLineDotIcon: {
    color: "#53b0cf",
    marginRight: "5px",
  },
  subscriber: {
    width: "100%",
    height: "100%",
    "& .OTSubscriberContainer,& .OT_widget-container ": {
      width: "100% !important",
      height: "100% !important",
    },
  },
  publisher: {
    position: "absolute",
    left: "15px",
    bottom: "15px",
    zIndex: 1,
  },
  cutTheCall: {
    position: "absolute",
    right: "15px",
    bottom: "15px",
    zIndex: 1,
    color: "#fff",
    border: "2px solid red",
    borderRadius: "30px",
    padding: "15px",
    background: "red",
  },
}));
var startCall;
var endCall;
export default function VideoCallComp({
  videoCallPayload,
  cutTheVideoCall,
  isFromCallModal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  startCall = 0;
  const handleClickOpen = () => {
    startCall = new Date();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cutTheCall = () => {
    endCall = new Date();
    var duration = moment(endCall).diff(moment(startCall), "seconds");
    var callPaypload = {
      clinicid: videoCallPayload.clinicid,
      doctorid: videoCallPayload.doctorid,
      patientid: videoCallPayload.kivipatientid,
      type: "video",
      duration: duration,
    };
    dispatch(addCallDuration(callPaypload));
    cutTheVideoCall();
  };

  return (
    <>
      {videoCallPayload && isFromCallModal ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
          style={{ background: "green", color: "#fff" }}
        >
          Join
        </Button>
      ) : (
        videoCallPayload && (
          <VideocamIcon
            className={classes.timeLineDotIcon}
            onClick={handleClickOpen}
          />
        )
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.mainDialog }}
        scroll="body"
        TransitionComponent={Transition}
      >
        <span className={classes.cutTheCall} onClick={cutTheCall}>
          <PhoneDisabledIcon />
        </span>
        <OTSession
          apiKey={videoCallPayload ? videoCallPayload.apikey : ""}
          sessionId={videoCallPayload ? videoCallPayload.sessionid : ""}
          token={videoCallPayload ? videoCallPayload.publishertoken : ""}
          className={classes.subscriber}
        >
          <OTPublisher className={classes.publisher} />
          <OTStreams className={classes.subscriber}>
            <OTSubscriber className={classes.subscriber} />
          </OTStreams>
        </OTSession>
      </Dialog>
    </>
  );
}
