import axios from "axios";
import CryptoJS from "crypto-js";

import { BASE_URL } from "../../../utils/apiHandler";
import { globalApiHandler } from "../../../utils/globalApiHandler";
import { isAcceptedTermsConditons } from "../../TermsConditions/action";
import { getFirebaseAdminData } from "../../PushNotifications/action";
import { setSocketConnection } from "../../Socket/action";
import { showSnackbar } from "../../Snackbar/action";
import { activeOverlay, disableOverlay } from "../../Overlay/action";

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
export const USER_VERIFY_ERROR = "USER_VERIFY_ERROR";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const GET_USER_DETAILS_SUCCESS_FAIL = "GET_USER_DETAILS_SUCCESS_FAIL";
export const GET_CLINIC_DETAILS = "GET_CLINIC_DETAILS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const loginSuccess = (data) => ({
  type: USER_LOGIN_SUCCESS,
  data: data,
});

export const loginFailure = (error) => async (dispatch) => {
  await dispatch({
    type: USER_LOGIN_FAILURE,
    data: error,
  });
};

const logoutSuccess = (data) => ({
  type: USER_LOGOUT_SUCCESS,
  data: data,
});

const verifySucess = (token) => ({
  type: USER_VERIFY_SUCCESS,
  data: token,
});
const verifyError = () => ({
  type: USER_VERIFY_ERROR,
});
const forgotUserpasswordAction = (data) => ({
  type: FORGOT_PASSWORD,
  data: data,
});
export const userDetailAction = (data) => ({
  type: GET_USER_DETAILS_SUCCESS_FAIL,
  data: data,
});

const getClinicDetailsAction = (data) => ({
  type: GET_CLINIC_DETAILS,
  data: data,
});

export const login = (user) => async (dispatch) => {
  var hash = CryptoJS.SHA1(user.password);
  var data = JSON.stringify({
    email: user.email,
    password: CryptoJS.enc.Hex.stringify(hash),
  });
  const config = {
    method: "post",
    url: `${BASE_URL}/authenticate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axios(config);
    const data = res.data;
    let isSucces = data.status;
    if (isSucces) {
      await localStorage.setItem("token", data.result.access_token);
      dispatch(loginSuccess(data.result.access_token));
      dispatch(isAcceptedTermsConditons());
      dispatch(getFirebaseAdminData());

      dispatch(getUserProfile());
    } else {
      dispatch(showSnackbar(data));
      dispatch(loginFailure(data));
    }
  } catch (err) {
    let mesaage = {};
    if (err.response === undefined) {
      mesaage["error_description"] = "Service Unavailable";
    } else {
      mesaage["error_description"] = "Invalid Email or Password";
    }
    dispatch({ type: "SERVER_ERROR", data: mesaage });
  }
};

export const getUserProfile = () => async (dispatch) =>  {
  try {
    var url = `${BASE_URL}/getprofile`;
    const res = await globalApiHandler(url, "post", {});
    const data = res.result;
    dispatch(userDetailAction(data.patient));
    dispatch(getClinicDetails(data.patient));
  } catch (err) {}
};


export const getClinicDetails = (userDetails) => async (dispatch) => {
  try {
    var clinicID = JSON.stringify({ clinicid: userDetails.clinic });
    var url = `${BASE_URL}/clinicdetails`;
    const res = await globalApiHandler(url, "post", clinicID);
    const data = res.result;
    dispatch(getClinicDetailsAction(data));
  } catch (err) {}
};

export const forgotUserpassword = (email) => async (dispatch) => {
  dispatch(activeOverlay());
  var url = `${process.env.REACT_APP_BASE_URL_NOAPI}/api/patientapi/forgotpassword?email=${email}`;
  try {
    const res = await axios.get(url);
    const data = res.data.data;
    dispatch(showSnackbar(data));
    dispatch(forgotUserpasswordAction(data));
    dispatch(disableOverlay());
  } catch (err) {
    dispatch(disableOverlay());
    console.log(err);
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("videoCall");
  localStorage.removeItem("notification-token");
  dispatch(setSocketConnection(null));
  dispatch(logoutSuccess());
};

export const verify = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (!!token) {
    dispatch(verifySucess(token));
    dispatch(getUserProfile());
    return;
  }
  dispatch(verifyError());
};
