import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        width: '95%',
        margin: 'auto',
        marginBottom: '0.5rem'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
    },
    pos: {
        marginBottom: 12,
    },
    cardHead: {
        color: '#26a0c9',
        padding: '0.7rem',
        borderBottom: '1px solid #ccc',
        flex: 1
    },
    cardHeadContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1
    },

});

function CardComp(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader
                classes={{
                    root: classes.cardHead,
                    content: classes.cardHeadContent,
                    title: classes.title
                }}
                title={props.heading}
            />
            <CardContent>
                {props.children}
            </CardContent>
        </Card>
    )
}

export default CardComp
    ;
