import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ChatIcon from "@material-ui/icons/Chat";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "139px",
    maxWidth: "500px",
    width: "100%",
  },
  fab: {
    position: "absolute",
    right: theme.spacing(2),
    background: "#26a0c9",
    "&:focus, &:hover": {
      background: "#26a0c9",
    },
  },
}));

export default function FloatingBtnComp(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fab
        color="primary"
        aria-label={props.label}
        onClick={props.onClick}
        className={classes.fab}
      >
        {props.module && props.module === "chat" ? <ChatIcon /> : <AddIcon />}
      </Fab>
    </div>
  );
}
