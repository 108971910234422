import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import InputComp from "../../../components/InputComp";
import ButtonComp from "../../../components/ButtonComp";

import { login } from "../../modules/Auth/Login/action";
import { Link } from "react-router-dom";
import { showSnackbar } from "../../modules/Snackbar/action";

const useStyles = makeStyles((theme) => ({
  loginForm: {
    background: "#ffffff",
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  forgotLink: {
    color: "#26a0c9",
  },
}));

function LoginFormCont() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [userCred, setUserCred] = useState({ email: "", password: "" });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserCred({ ...userCred, [name]: value });
  };

  const handleClick = () => {
    if (
      userCred.email &&
      userCred.email.length &&
      userCred.password &&
      userCred.password.length
    ) {
      dispatch(login(userCred));
    } else {
      return dispatch(
        showSnackbar({
          status: 0,
          message: "All fields are mandetory",
        })
      );
    }
  };

  return (
    <div className="mt-3 container">
      <div className={`${classes.loginForm} row`}>
        <div className="col-8">
          <h2 className="mb-3">Login</h2>
          <div>
            <InputComp
              label="Email *"
              name="email"
              onChange={handleInputChange}
              value={userCred.email}
              type="email"
            >
              <MailOutlineIcon />
            </InputComp>
            <InputComp
              label="Password *"
              name="password"
              type="password"
              onChange={handleInputChange}
              value={userCred.password}
            >
              <LockOpenIcon />
            </InputComp>
            <div className="d-flex justify-content-end mb-3">
              <Link to="/forgotPasword" className={classes.forgotLink}>
                Forgot Password ?
              </Link>
            </div>
            <ButtonComp label="Login" onClick={handleClick} />
            <div>
              Don't have an account ? <Link to="/signup">Register</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginFormCont;
