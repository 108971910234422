import React, { useState } from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import { IconButton, makeStyles } from "@material-ui/core";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import CancelIcon from "@material-ui/icons/Cancel";

import ImagePreview from "./ImagePreview";
import "./index.scss";

const useStyles = makeStyles((theme) => ({
  cameraClass: {
    position: "fixed",
    zIndex: 999999,
    background: "#000000 !important",
    right: "0px",
    bottom: "0px",
    top: "0px",
    left: "0px",
  },
  flipButton: {
    padding: 10,
    color: "#fff",
    position: "fixed",
    background: "#ffffff66",
    right: "10%",
    bottom: "65px",
    height: "40px",
    width: "40px",
    "&:hover": {
      background: "#ffffff66",
    },
  },
  cancelButton: {
    padding: 10,
    color: "#fff",
    position: "fixed",
    background: "#ffffff66",
    left: "10%",
    bottom: "65px",
    height: "40px",
    width: "40px",
    "&:hover": {
      background: "#ffffff66",
    },
  },
}));

function CameraComp({ drDetails, sendMessage, manageCameraState }) {
  const classes = useStyles();

  const [dataUri, setDataUri] = useState("");
  const [flipState, stFlipState] = useState("environment");

  function handleTakePhotoAnimationDone(dataUri) {
    setDataUri(dataUri);
  }

  const handleFlip = () => {
    stFlipState(!flipState);
  };

  const isFullscreen = true;
  return (
    <div className={classes.cameraClass}>
      {dataUri ? (
        <ImagePreview
          dataUri={dataUri}
          isFullscreen={isFullscreen}
          drDetails={drDetails}
          sendMessage={sendMessage}
          manageCameraState={manageCameraState}
        />
      ) : (
        <>
          <Camera
            idealFacingMode={flipState ? "environment" : "user"}
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            isFullscreen={isFullscreen}
            isImageMirror={false}
          />
          <IconButton
            className={classes.flipButton}
            onClick={(e) => handleFlip(e)}
            aria-label="Flip"
          >
            <FlipCameraAndroidIcon />
          </IconButton>
          <IconButton
            className={classes.cancelButton}
            onClick={() => manageCameraState()}
            aria-label="Close"
          >
            <CancelIcon />
          </IconButton>
        </>
      )}
    </div>
  );
}

export default CameraComp;
