export const timelineFilterOptions = [
  "All",
  "Prescriptions",
  "Treatment Plans",
  "Files",
  "Telecall logs",
];

export const teleCom = ["Chat", "Call", "Skype", "Kivi video call"];
export const bloodGroup = ["A+", "B+", "O+", "AB+", "A-", "B-", "AB-"];
