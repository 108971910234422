import React from "react";

import ChangePasswordCont from "../../../containers/MyAccount/ChangePasswordCont";
import PatientHeaderComp from "../../../../components/PatientHeaderComp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";

function ChangePassword() {
  return (
    <>
      <PatientHeaderComp
        heading={
          <div className="d-flex">
            <Link
              to="/myaccount"
              style={{ marginRight: "auto", color: "#444" }}
            >
              <ArrowBackIcon />
            </Link>
            <span style={{ flex: 1 }}>Change Password</span>
          </div>
        }
      />
      <ChangePasswordCont />
    </>
  );
}

export default ChangePassword;
