import React, { useRef, useEffect } from "react";

import ScrollToBottom from "react-scroll-to-bottom";

import Message from "./Message";

import "./index.scss";
import OverlayComp from "../OverlayComp";

const Messages = ({ messages, currentUser, isActiveOverlay }) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const refDiv = messagesEndRef.current;
    setTimeout(() => {
      refDiv.scrollIntoView();
    }, 400);
  }, [messages]);

  return (
    <ScrollToBottom className="messages">
      {!isActiveOverlay && messages ? (
        messages.map((message, i) => (
          <div key={i}>
            <Message message={message} currentUser={currentUser} />
          </div>
        ))
      ) : (
        <OverlayComp open={isActiveOverlay} />
      )}
      <div ref={messagesEndRef} />
    </ScrollToBottom>
  );
};

export default Messages;
