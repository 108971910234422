import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TimelineIcon from "@material-ui/icons/Timeline";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ChatIcon from "@material-ui/icons/Chat";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    maxWidth: "500px",
    "& a:hover": {
      textDecoration: "none",
    },
  },
  selected: {
    color: "#1599c5 !important",
  },
  iconOnly: {
    color: "#1599c5 !important",
  },
});

export default function PatientFooterComp(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState("recents");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { pathname } = useLocation();

  const checkVisiblityStatus = () => {
    if (pathname === "/chatbox") {
      return false;
    }
    return true;
  };
  return checkVisiblityStatus() ? (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        component={Link}
        to="/timeline"
        className={pathname === "/timeline" ? classes.selected : ""}
        label="Timeline"
        value="timeline"
        icon={<TimelineIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/appointment"
        className={pathname === "/appointment" ? classes.selected : ""}
        label="Appointments"
        value="appointments"
        icon={<EventNoteIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/chat"
        className={pathname === "/chat" ? classes.selected : ""}
        label="Kivi Consult"
        value="chat"
        icon={<ChatIcon />}
      />
      <BottomNavigationAction
        label="My Account"
        component={Link}
        to="/myaccount"
        className={pathname === "/myaccount" ? classes.selected : ""}
        value="account"
        icon={<PermIdentityIcon />}
      />
    </BottomNavigation>
  ) : (
    <div></div>
  );
}
