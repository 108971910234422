import React from "react";

import ChatBoxCont from "../../../containers/Chat/ChatBoxCont";

function ChatWindow() {
  return (
    <>
      <ChatBoxCont />
    </>
  );
}

export default ChatWindow;
