import React from "react";
import { Link } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddAppointmentCont from "../../../containers/Appointment/AddAppointmentCont";
import PatientHeaderComp from "../../../../components/PatientHeaderComp";

function AddAppointment() {
  return (
    <>
      <PatientHeaderComp
        heading={
          <div className="d-flex">
            <Link
              to="/appointment"
              style={{ marginRight: "auto", color: "#444" }}
            >
              <ArrowBackIcon />
            </Link>
            <span style={{ flex: 1 }}>Book Appointment</span>
          </div>
        }
      />{" "}
      <AddAppointmentCont />
    </>
  );
}

export default AddAppointment;
