import { BASE_URL } from "../../../utils/apiHandler";
import { globalApiHandler } from "../../../utils/globalApiHandler";
import { disableOverlay, activeOverlay } from "../../Overlay/action";


export const GET_PRESCRIPTION = "GET_PRESCRIPTION";
export const GET_TREATMENTPLAN = "GET_TREATMENTPLAN";
export const GET_PATIENT_FILES = "GET_PATIENT_FILES";
export const GET_TELE_CALL_LOG = "GET_TELE_CALL_LOG";
export const SET_FILTER_TIMELINE = "SET_FILTER_TIMELINE";

const getPrescriptionsAction = data => ({
    type: GET_PRESCRIPTION,
    data: data
})
const getTreatmentplansAction = data => ({
    type: GET_TREATMENTPLAN,
    data: data
})
const getPatientFilesAction = data => ({
    type: GET_PATIENT_FILES,
    data: data
})
const getTeleCallLogAction = data => ({
    type: GET_TELE_CALL_LOG,
    data: data
})
const setTimeLineFilterAction = data => ({
    type: SET_FILTER_TIMELINE,
    data: data
})

export const getPrescriptions = () => async dispatch => {
    dispatch(activeOverlay());
    var data = '';
    const url = `${BASE_URL}/prescription`

    try {
        const res = await globalApiHandler(url, "post", data);
        const result = res.result;
        dispatch(getPrescriptionsAction(result));
        dispatch(disableOverlay());
    } catch (err) {

    }
};

export const getTreatmentplans = () => async dispatch => {
    dispatch(activeOverlay());
    var data = '';
    const url = `${BASE_URL}/treatmentplans`

    try {
        const res = await globalApiHandler(url, "post", data);
        const result = res.result;
        dispatch(getTreatmentplansAction(result));
        dispatch(disableOverlay());
    } catch (err) {

    }
};
export const getPatientFiles = () => async dispatch => {
    dispatch(activeOverlay());
    var data = '';
    const url = `${BASE_URL}/patientfiles`

    try {
        const res = await globalApiHandler(url, "post", data);
        const result = res.result;
        dispatch(getPatientFilesAction(result));
        dispatch(disableOverlay());
    } catch (err) {

    }
};
export const getTeleCallLog = () => async dispatch => {
    dispatch(activeOverlay());
    var data = '';
    const url = `${BASE_URL}/gettelecalllog`

    try {
        const res = await globalApiHandler(url, "post", data);
        const result = res.result;
        dispatch(getTeleCallLogAction(result));
        dispatch(disableOverlay());
    } catch (err) {

    }
};
export const setTimeLineFilter = (value) => async dispatch => {

    try {
        dispatch(setTimeLineFilterAction(value));
    } catch (err) {

    }
};
