import React, { useState, useEffect } from "react";

import {
  Typography,
  makeStyles,
  Slide,
  AppBar,
  Toolbar,
  Avatar,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import FaceIcon from "@material-ui/icons/Face";
import { useLocation, Redirect, Link, useParams } from "react-router-dom";
import VideoCallComp from "../../../components/VideoCallComp";
import ChatInputBoxComp from "../../../components/ChatInputBoxComp";
import ChatMessageComp from "../../../components/ChatMessageComp";
import { activeOverlay, disableOverlay } from "../../modules/Overlay/action";
import CameraComp from "../../../components/CameraComp";

let textHistory;

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  appBar: {
    position: "fixed",
    background: "#26a0c9",
    maxWidth: "500px",
    right: "auto",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#fff",
    whiteSpace: "nowrap",
  },
  secondaryMessage: {
    display: "flex",
    justifyContent: "space-between",
  },
  secondaryMsgText: {
    maxWidth: "50%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  cameraStyle: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  videoHead: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#fff",
    display: "flex",
    justifyContent: "flex-end",
  },
  ".MuiBottomNavigation-root": {
    display: "none",
  },
}));

const ChatBoxCont = () => {
  const classes = useStyles();
  let location = useLocation();
  let params = useParams();
  const dispatch = useDispatch();

  const [isCameraOpen, setCameraState] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  var doctorData;

  var module = location.module;

  const {
    apiSocket,
    videoCallPayload,
    setChatHistory,
    newMessage,
  } = useSelector((state) => state.Patient.socketCon);

  const { clinicDetails, token, user } = useSelector(
    (state) => state.Patient.login
  );

  useEffect(() => {
    return () => {
      let roomname =
        "kivi-" + (doctorData && doctorData.id) + "-" + (user && user.id);

      var roomdata = {
        from: "kivi",
        data: {
          roomname: roomname,
          doctor: {
            id: doctorData && doctorData.id,
            name: doctorData && doctorData.name,
          },
        },
      };
      roomdata = JSON.stringify(roomdata);
      if (apiSocket) {
        apiSocket.emit("leaveroom", roomdata);
      }
    };
  }, []);
  useEffect(() => {
    if (apiSocket && token && clinicDetails) {
      receiveMessage();
    }
  }, [clinicDetails, token, apiSocket]);

  useEffect(() => {
    if (setChatHistory) {
      textHistory = setChatHistory;
      setMessageHistory(setChatHistory);
    }
  }, [setChatHistory]);

  useEffect(() => {
    if (newMessage) {
      getNewMessage(newMessage);
    }
  }, [newMessage]);

  if (!location.doctor && !params.doctorId) {
    return <Redirect to="/doctorsList" />;
  }

  if (params.doctorId) {
    doctorData =
      clinicDetails &&
      clinicDetails.consultants.filter(
        (value) => value.id.toString() === params.doctorId
      )[0];
  }

  const isVideoPayload = async () => {
    var aptDoctorId = doctorData && doctorData.id;
    var aptClinicId = clinicDetails && clinicDetails.clinic.id;

    var videoCallPayloadData = JSON.parse(localStorage.getItem("videoCall"));

    if (
      videoCallPayloadData &&
      videoCallPayloadData.doctorid === aptDoctorId &&
      videoCallPayloadData.clinicid === aptClinicId
    ) {
      return true;
    }
    return false;
  };

  const setCameraOn = () => {
    setCameraState(true);
  };

  const setCameraOff = () => {
    setCameraState(false);
  };

  const inputBoxButton = () => {
    return (
      <ChatInputBoxComp
        manageCameraState={setCameraOn}
        sendMessage={sendMessage}
        drDetails={doctorData}
      />
    );
  };

  const messageHistoryBox = () => {
    return (
      <ChatMessageComp
        messages={messageHistory}
        currentUser={user}
        // isActiveOverlay={props.isActiveOverlay}
      />
    );
  };

  const sendMessage = (type, event, message, drDetails, fileData) => {
    var payload = {
      from: "kivi",
      data: {
        netmedspatientid: null,
        consultationid: null,
        doctorid: drDetails.id,
        clinicid: clinicDetails && clinicDetails.clinic.id,
        message: message,
        messagetype: type,
        filename: type === "file" ? fileData.name : "",
        access_token: token,
      },
    };
    var sentData = {
      createtimestamp: (Date.parse(new Date()) / 1000).toString(),
      fromuser: user && user.id.toString(),
      message: message,
      messagetype: type,
    };
    getNewMessage(sentData);
    payload = JSON.stringify(payload);
    if (apiSocket) {
      apiSocket.emit("receiveMessage", payload);
    }
    event.preventDefault();
  };

  const getNewMessage = (data) => {
    textHistory = textHistory && textHistory.concat(data);
    setMessageHistory(textHistory);
  };

  const cutTheVideoCall = () => {
    if (videoCallPayload && apiSocket) {
      localStorage.removeItem("videoCall");
      var payload = {
        from: "patient",
        kivipatientid: videoCallPayload.kivipatientid,
        netmedspatientid: null,
        consultationid: null,
        clinicid: videoCallPayload.clinicid,
        doctorid: videoCallPayload.doctorid,
        sessionid: videoCallPayload.sessionid,
        access_token: token,
      };
      payload = JSON.stringify(payload);
      apiSocket.emit("disconnectVideoCall", { data: payload });
    }
  };

  const receiveMessage = () => {
    textHistory = [];
    dispatch(activeOverlay());
    setMessageHistory([]);
    setTimeout(() => {
      dispatch(disableOverlay());
    }, 2000);
    let roomname =
      "kivi-" + (doctorData && doctorData.id) + "-" + (user && user.id);

    var roomdata = {
      from: "kivi",
      data: {
        roomname: roomname,
        doctor: {
          id: doctorData && doctorData.id,
          name: doctorData && doctorData.name,
        },
      },
    };
    roomdata = JSON.stringify(roomdata);

    var payload = {
      doctorid: doctorData && doctorData.id,
      clinicid: clinicDetails && clinicDetails.clinic.id,
      page: "1",
      access_token: token, // patient access_token
    };
    payload = JSON.stringify(payload);

    if (apiSocket) {
      apiSocket.emit("joinroom", roomdata);
      apiSocket.emit("fetchMessage", { data: payload });
    }
  };

  return (
    <div style={{ marginTop: "70px" }}>
      {isCameraOpen && (
        <div className={classes.cameraStyle}>
          <CameraComp
            drDetails={user}
            sendMessage={sendMessage}
            manageCameraState={setCameraOff}
          />
        </div>
      )}
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            component={Link}
            to={module && module === "doctorList" ? "/doctorsList" : "/chat"}
            aria-label="close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Avatar src={doctorData && doctorData.profilepic}>
            <FaceIcon />
          </Avatar>
          <Typography variant="h6" className={classes.title}>
            {doctorData && doctorData.name}
          </Typography>
          <Typography variant="h6" className={classes.videoHead}>
            {isVideoPayload() === true && (
              <VideoCallComp
                videoCallPayload={videoCallPayload}
                cutTheVideoCall={cutTheVideoCall}
              />
            )}
          </Typography>
        </Toolbar>
      </AppBar>
      {messageHistoryBox()}
      {inputBoxButton()}
    </div>
  );
};

export default ChatBoxCont;
