import React from 'react';

import PatientHeaderComp from '../../../components/PatientHeaderComp'
import NewChatCont from '../../containers/Chat/NewChatCont';

function Chat() {

    return (
        <>
            <PatientHeaderComp heading="Chat" />
            <NewChatCont />
        </>
    );
}

export default Chat;
