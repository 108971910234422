import React from "react";

import ForgotPasswordCont from "../../../containers/Auth/ForgotPasswordCont";
import PatientHeaderComp from "../../../../components/PatientHeaderComp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";

function ForgotPwd() {
  return (
    <>
      <PatientHeaderComp
        heading={
          <div className="d-flex">
            <Link to="/login" style={{ marginRight: "auto", color: "#444" }}>
              <ArrowBackIcon />
            </Link>
            <span style={{ flex: 1 }}>Forgot Password</span>
          </div>
        }
      />
      <ForgotPasswordCont />
    </>
  );
}

export default ForgotPwd;
