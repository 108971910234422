import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import InputComp from "../../../components/InputComp";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Paper } from "@material-ui/core";
import { changeUserpassword } from "../../modules/Common/action";
import ButtonComp from "../../../components/ButtonComp";
import { showSnackbar } from "../../modules/Snackbar/action";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    margin: "auto",
    marginTop: "3rem",
    borderRadius: "5px",
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#000",
    },
  },
  paperRoot: {
    paddingTop: "0.5rem",
  },
}));

const ChangePasswordCont = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, clinicDetails } = useSelector((state) => state.Patient.login);

  const [formData, setFormData] = useState({
    password: "",
    newpassword: "",
  });

  const handleChange = (event) => {
    var { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (formData.password.length && formData.newpassword.length) {
      dispatch(changeUserpassword(formData));
    } else {
      return dispatch(
        showSnackbar({
          status: 0,
          message: "All fields are mandetory",
        })
      );
    }
  };

  return user && clinicDetails ? (
    <div className={classes.paperRoot}>
      <Paper className={classes.root}>
        <InputComp
          label="Old Password"
          name="password"
          type="password"
          onChange={handleChange}
          value={formData.password}
        >
          <LockOpenIcon />
        </InputComp>
        <InputComp
          label="New Password"
          name="newpassword"
          type="password"
          onChange={handleChange}
          value={formData.newpassword}
        >
          <LockOpenIcon />
        </InputComp>
        <br />
      </Paper>

      <div className="d-flex justify-content-center">
        <ButtonComp label="Submit" onClick={handleSubmit} />
      </div>
      <br />
    </div>
  ) : (
    <></>
  );
};

export default ChangePasswordCont;
