import { PAYMENT_ORDER_IDS, GET_PAYMENT_STATUS } from "./action";

const initialState = {
  paymentOrderids: null,
  getPaymentStatus: null,
};

export default function paymentReducder(state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case PAYMENT_ORDER_IDS:
      return {
        ...state,
        paymentOrderids: data,
      };
    case GET_PAYMENT_STATUS:
      return {
        ...state,
        paymentOrderids: null,
        getPaymentStatus: data,
      };
    default:
      return state;
  }
}
