import React, { useState } from "react";

import PatientApp from "./Patient/views/PatientApp";

import "./App.css";
import { useClearCache } from "react-clear-cache";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

function App() {
  const [open, setOpen] = useState(true);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (e) => {
    emptyCacheStorage();
    setOpen(false);
    e.preventDefault();
  };

  return (
    <>
      {!isLatestVersion && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText>Update is available</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary" autoFocus>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className="App">
        <PatientApp />
      </div>
    </>
  );
}

export default App;
