import React, { useState } from "react";

import image from "../../assets/images/kividoc.png";

const ClinicSplashComp = ({ clinicDetails }) => {
  const [splash, setSplash] = useState(true);

  const imageSrc =
    clinicDetails && clinicDetails.clinic
      ? clinicDetails.clinic.profilepic
      : image;
  const clinicName =
    clinicDetails && clinicDetails.clinic ? clinicDetails.clinic.name : "";

  const showSpalhScreen = () => {
    setTimeout(() => {
      setSplash(false);
    }, 3000);
    return true;
  };
  return (
    <>
      {showSpalhScreen() && splash && (
        <div
          style={{
            zIndex: "999999",
            height: "100%",
            width: "100%",
            position: "fixed",
            right: 0,
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={imageSrc}
            width="200px"
            style={{
              marginBottom: "25px",
            }}
            alt={clinicName}
          />
          <h4>{clinicName}</h4>
        </div>
      )}
    </>
  );
};

export default ClinicSplashComp;
