import React from "react";

import ChatModalComp from "../../../components/ChatModalComp";
import { convertTimeStamp } from "../../utils/helper";

import { Divider, makeStyles, Slide, Typography } from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  secondaryMessage: {
    display: "flex",
    justifyContent: "space-between",
  },
  secondaryMsgText: {
    maxWidth: "50%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  link: {
    color: "black",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
}));

const AlreadyChatListCont = () => {
  const classes = useStyles();
  const { prevChatDataList } = useSelector((state) => state.Patient.socketCon);
  const { clinicDetails } = useSelector((state) => state.Patient.login);
  return (
    <>
      {prevChatDataList &&
        prevChatDataList.length > 0 &&
        prevChatDataList
          .sort((a, b) => b.createtimestamp - a.createtimestamp)
          .map((value, index) => {
            var doctor =
              clinicDetails &&
              clinicDetails.consultants.filter(
                (e) => e.id.toString() === value.doctorid.toString()
              )[0];
            return (
              <List
                className={classes.link}
                component={Link}
                to={{
                  pathname: `/chatbox/${doctor.id}`,
                  doctor: doctor,
                  module: "chatBoard",
                }}
                key={index}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={doctor.profilepic}>
                      <FaceIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={doctor.name}
                    secondary={
                      value.message ? (
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.secondaryMessage}
                          >
                            <span className={classes.secondaryMsgText}>
                              {value.messagetype !== "text"
                                ? value.messagetype
                                : value.message}
                            </span>
                            <span>
                              {convertTimeStamp(value.createtimestamp)}
                            </span>
                          </Typography>
                        </>
                      ) : null
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            );
          })}
      {prevChatDataList && !prevChatDataList.length && (
        <div className="pt-5">No data available</div>
      )}
    </>
  );
};

export default AlreadyChatListCont;
