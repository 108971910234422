import {
  API_SOCKET,
  VIDEO_CALL_PAYLOAD,
  SET_CHAT_HISTORY,
  SET_PREV_CHAT_DATA,
  NEW_MESSAGE_RECIEVED,
} from "./action";

const initialState = {
  apiSocket: null,
  videoCallPayload: null,
  prevChatDataList: null,
  setChatHistory: null,
  newMessage: null,
};

export default function socketReducder(state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case API_SOCKET:
      return {
        ...state,
        apiSocket: data,
        videoCallPayload: null,
        prevChatDataList: null,
        setChatHistory: null,
        newMessage: null,
      };
    case VIDEO_CALL_PAYLOAD:
      return {
        ...state,
        videoCallPayload: data,
      };
    case SET_PREV_CHAT_DATA:
      return {
        ...state,
        prevChatDataList: data,
        setChatHistory: null,
        newMessage: null,
      };
    case SET_CHAT_HISTORY:
      return {
        ...state,
        setChatHistory: data,
      };
    case NEW_MESSAGE_RECIEVED:
      return {
        ...state,
        newMessage: data,
      };
    default:
      return state;
  }
}
