import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';

import './index.scss';

const useStyles = makeStyles((theme) => ({
    sendIconButton: {
        padding: 10,
        color: "#26a0c9",
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        background: '#ffffff14'
    },
    cancelIconButton: {
        padding: 10,
        color: "#26a0c9",
        position: 'fixed',
        bottom: '10px',
        left: '10px',
        background: '#ffffff14'
    },
}));

export const ImagePreview = ({ dataUri, isFullscreen, drDetails, sendMessage, manageCameraState }) => {
    const classes = useStyles();

    let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

    const handleSubmit = (event) => {
        manageCameraState()
        sendMessage("file", event, dataUri, drDetails, { name: "camera_image" })
    }
    const handleCancel = (event) => {
        manageCameraState()
    }

    return (
        <div className={'demo-image-preview ' + classNameFullscreen}>
            <img src={dataUri} alt="Preview" />
            <IconButton
                className={classes.sendIconButton}
                onClick={(e) => handleSubmit(e)}
                aria-label="Send"
            >
                <SendIcon />
            </IconButton>
            <IconButton
                className={classes.cancelIconButton}
                onClick={(e) => handleCancel(e)}
                aria-label="Cancel"
            >
                <CancelIcon />
            </IconButton>
        </div>
    );
};


export default ImagePreview;