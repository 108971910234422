import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getAppointmentList } from "../../modules/Appointment/AppointmentBoard/action";
import { formatStartDatetime } from "../../utils/helper";
import CardComp from "../../../components/CardComp";
import { Link } from "react-router-dom";
import FloatingBtnComp from "../../../components/FloatingBtnComp";

const AppointmentCont = ({
  appointmentList,
  bookedAppointment,
  getAppointmentList,
}) => {
  useEffect(() => {
    getAppointmentList();
  }, [bookedAppointment, getAppointmentList]);

  const sortArray = (array) => {
    return array.sort((a, b) =>
      new Date(b.StartDatetime) > new Date(a.StartDatetime) ? 1 : -1
    );
  };

  return (
    <div style={{ paddingTop: "35px" }}>
      {appointmentList &&
        sortArray(appointmentList.appointments).map((data, index) => {
          return (
            <CardComp data={data} heading={data.doctor.name} key={index}>
              <table className="w-100 text-left">
                <tbody>
                  <tr>
                    <td className="w-50">
                      <b>Patient</b>
                    </td>
                    <td>
                      <span>{data.patientname}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Reason</b>
                    </td>
                    <td>
                      <span>{data.reason}</span>
                    </td>
                  </tr>
                  {data.isteleappointment === 1 && (
                    <tr>
                      <td>
                        <b>Tele appointment</b>
                      </td>
                      <td>
                        <span>{data.teleappointmentchannel}</span>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <b>Date</b>
                    </td>
                    <td>
                      <span>{formatStartDatetime(data.StartDatetime)}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardComp>
          );
        })}
      {!appointmentList && <div className="pt-5">No appointment available</div>}
      <Link to="/bookAppointment">
        <FloatingBtnComp module="apoointment" />
      </Link>
      {/* <AddAppointment /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.Patient.appointmentBoard.appointmentList,
  bookedAppointment: state.Patient.appointmentBoard.bookedAppointment,
});

const mapDispatchToProps = {
  getAppointmentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCont);
