import React, { useEffect, useState } from "react";
import moment from "moment";

const TimerComp = ({ currentTime, numTime, timeType, timesUpCheck }) => {
    const calculateTimeLeft = () => {
        const difference = +moment(currentTime).add(numTime, timeType) - +new Date();
        let timeLeft = {};

        var minutes = Math.floor((difference / 1000 / 60) % 60)
        var seconds = Math.floor((difference / 1000) % 60)
        if (difference > 0) {
            timeLeft = {
                minutes: minutes,
                seconds: seconds
            };
        }
        var isTimeUp = (minutes * 60) + seconds
        if (isTimeUp === 0) {
            timesUpCheck(true)
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents = [];
    timerComponents.push(
        <span>
            {timeLeft['minutes']}{":"}{timeLeft['seconds']}
        </span>
    );

    return (
        <div style={{ color: "#169cca" }}>
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
    );
}
export default TimerComp;