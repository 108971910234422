import React from "react";

import PatientFooterComp from "../../../components/PatientFooterComp";

const PatientFooterCont = () => {
  return (
    <>
      <PatientFooterComp />
    </>
  );
};

export default PatientFooterCont;
