import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  button: {
    borderRadius: "20px",
    padding: "0.5em 4em",
    background: "#26a0c9",
    "&:hover": {
      background: "#3ba7cb",
    },
  },
}));

export default function ButtonComp(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={props.onClick}
        disabled={props.isDisable ? props.isDisable : false}
      >
        {props.label}
      </Button>
    </div>
  );
}
