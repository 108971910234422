import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import VideocamIcon from '@material-ui/icons/Videocam';
import Paper from '@material-ui/core/Paper';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AlarmIcon from '@material-ui/icons/Alarm';
import HistoryIcon from '@material-ui/icons/History';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import EditIcon from '@material-ui/icons/Edit';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

import pdfImage from "../../assets/images/pdf.png";
import { Typography } from '@material-ui/core';
import { convertTimeStamp } from '../../Patient/utils/helper';

const useStyles = makeStyles((theme) => ({

    timeLineDot: {
        color: "#979797",
        marginBottom: 0
    },
    timeLineDotIconHeader: {
        color: "#53b0cf",
    },
    timeLineDotIcon: {
        color: "#53b0cf",
        marginRight: '5px'
    },
    missingOppositeContent: {
        "&:before": {
            display: "none"
        }
    },
    heading: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 8px 14px 8px',
        borderBottom: '1px solid #ccc',
        alignItems: 'flex-end'
    },
    breakText: {
        whiteSpace: 'break-spaces',
        textAlign: 'left',
        textIndent: '0px',
        marginLeft: '10px'
    },
    button: {
        background: '#f97e7b',
        color: "#fff !important",
        borderRadius: '20px',
        padding: '5px 10px',
        fontSize: '12px',
        "&:hover": {
            color: "#fff",
            textDecoration: 'none'
        }
    },
    pdfDownload: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: "10px"
    },
    listStyle: {
        listStyleType: 'disc'
    }
}));

function TimeLineComp(props) {
    const classes = useStyles();


    const getHeading = () => {
        switch (props.type) {
            case 'telecalllogs': return (
                <span className="text-left">Call Log</span>
            )
            case 'treatmentplans': return (
                <span className="text-left">Treatment Plan by {props.data.doctorname}</span>
            )
            case 'prescription': return (
                <span className="text-left">Prescription by {props.data.doctorname}</span>
            )
            case 'files': return (
                <span className="text-left">File</span>
            )
            default: return (<span></span>)
        }
    }

    const getHeadIcon = () => {
        switch (props.type) {
            case 'telecalllogs': return (
                <VideocamIcon className={classes.timeLineDotIcon} />
            )
            case 'treatmentplans': return
            case 'prescription': return (
                <a href={props.data.filename} className={classes.button}>Download</a>)
            case 'files': return
            default: return (<span></span>)
        }
    }

    const getBodyContent = () => {
        switch (props.type) {
            case 'telecalllogs': return getCallLogUI()
            case 'treatmentplans': return treatmentplansUI()
            case 'prescription': return getPrescriptionsUI()
            case 'files': return getFilesUI()
            default: return (<span></span>)
        }
    }

    const getFilesUI = () => {
        return (
            <div className={classes.pdfDownload}>
                <img src={props.data.filename} alt="Files uploaded" height="200px" width="100%" />
            </div>)
    }

    const treatmentplansUI = () => {
        return (
            <div className={classes.pdfDownload}>
                <a href={props.data.filename}>
                    <img src={pdfImage} height="25px" aria-hidden="true" alt="pdf" />
                    Click to download</a>
                <a href={props.data.filename} className={`pl-4 pr-4 ${classes.button}`}>Pdf</a>
            </div>
        )
    }

    const getCallLogUI = () => {
        return (
            <div className="text-left p-3">
                <h6 className="d-flex align-items-center">
                    <AddBoxIcon className={classes.timeLineDotIcon} />Clinic</h6>
                <div className="ml-4 mb-3">{props.data.clinic.name}</div>

                <h6 className="d-flex align-items-center">
                    <PermIdentityIcon className={classes.timeLineDotIcon} />Doctor</h6>
                <div className="ml-4 mb-3">{props.data.doctor.name}</div>

                <h6 className="d-flex align-items-center">
                    <AlarmIcon className={classes.timeLineDotIcon} />Time Duration</h6>
                <div className="ml-4 mb-3">{props.data.duration ? props.data.duration :
                    <Typography className="text-danger">
                        Missed Call</Typography>}
                </div>
            </div>
        )
    }

    const getPrescriptionsUI = () => {
        return (
            <div>
                {props.data.history && (
                    <div className={classes.breakText}>
                        <h6 className="d-flex align-items-center">
                            <HistoryIcon className={classes.timeLineDotIcon} />History</h6>
                        <div className="ml-4 mb-3">
                            {props.data.history.replace(/↵/g, "<br/>")}
                        </div>
                    </div>
                )}
                {props.data.comments && (
                    <div className={classes.breakText}>
                        <h6 className="d-flex align-items-center">
                            <BookmarkIcon className={classes.timeLineDotIcon} />Complaints</h6>
                        <div className="ml-4 mb-3">
                            {props.data.comments.replace(/↵/g, "<br/>")}
                        </div>
                    </div>
                )}
                {props.data.diagnosis.length > 0 && (
                    <div className={classes.breakText}>
                        <h6 className="d-flex align-items-center">
                            <FilterVintageIcon className={classes.timeLineDotIcon} />Diagnosis</h6>
                        <div className="ml-4 mb-3">
                            {props.data.diagnosis.map((value) => {
                                return (
                                    <div key={value.diagnosis}>- {value.diagnosis}</div>
                                )
                            })}
                        </div>
                    </div>
                )}
                {props.data.medicine.length > 0 && (
                    <div className={classes.breakText}>
                        <h6 className="d-flex align-items-center">
                            <LocalHospitalIcon className={classes.timeLineDotIcon} />Medicines</h6>
                        <div className="mb-3">
                            <ul className={classes.listStyle}>
                                {props.data.medicine.map((value) => {
                                    return (
                                        <li key={value.medicinename}>
                                            <div>{value.medicinename}</div>
                                            <div>
                                                {value.morning}-
                                                {value.noon}-
                                                {value.night},  {value.duration} {value.durationtype}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                )}
                {props.data.tests && (
                    <div className={classes.breakText}>
                        <h6 className="d-flex align-items-center">
                            <AssignmentTurnedInIcon className={classes.timeLineDotIcon} />Tests</h6>
                        <div className="ml-4 mb-3">
                            {props.data.tests.replace(/↵/g, "<br/>")}
                        </div>
                    </div>
                )}
                {props.data.advices && (
                    <div className={classes.breakText}>
                        <h6 className="d-flex align-items-center">
                            <EditIcon className={classes.timeLineDotIcon} />Advices</h6>
                        <div className="ml-4 mb-3">
                            {props.data.advices.replace(/↵/g, "<br/>")}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const getFormattedDate = () => {

        const timeStamp = props.data.uploadtime ? props.data.uploadtime : props.data.createdat
        return (
            <div
                className="d-flex justify-content-end p-2"
                style={{ color: "#53b0cf" }}>
                {convertTimeStamp(timeStamp)}
            </div>
        )
    }


    return (
        <Timeline>
            <TimelineItem classes={{
                missingOppositeContent: classes.missingOppositeContent,
            }}>
                <TimelineSeparator>
                    <TimelineDot className={classes.timeLineDot} variant="outlined">
                        {props.type === "telecalllogs" ?
                            <VideocamIcon className={classes.timeLineDotIconHeader} /> :
                            <DateRangeIcon className={classes.timeLineDotIconHeader} />}
                    </TimelineDot>
                    <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        <h5 className={classes.heading}>
                            {getHeading()}
                            {getHeadIcon()}
                        </h5>
                        {getBodyContent()}
                        {getFormattedDate()}
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}



export default TimeLineComp;
