import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import TimeLineComp from '../../../components/TimeLineComp'
import SelectComp from '../../../components/SelectComp';

import {
    getPrescriptions,
    getTreatmentplans,
    getPatientFiles,
    getTeleCallLog,
    setTimeLineFilter
} from '../../modules/TimeLine/TimeLineBoard/action'

import { timelineFilterOptions } from '../../utils/constant'

const TimeLineCont = ({
    prescriptionDetails,
    treatmentPlans,
    patientFiles,
    callLogs,
    selectedFilter,
    getPrescriptions,
    getTreatmentplans,
    getPatientFiles,
    getTeleCallLog,
    setTimeLineFilter }) => {


    const [timeineSortedList, setTimeineSortedList] = useState([])

    const getTimeLineData = () => {
        getPrescriptions()
        getTreatmentplans()
        getPatientFiles()
        getTeleCallLog()
    }

    useEffect(() => {
        if (selectedFilter === 'All') {
            getTimeLineData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilter])

    useEffect(() => {
        prescriptionDetails && setTimeineSortedList([
            ...timeineSortedList,
            ...prescriptionDetails.prescription.map((value) => {
                value['timeline_type'] = 'prescription';
                return value;
            })
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescriptionDetails])

    useEffect(() => {
        treatmentPlans && setTimeineSortedList([
            ...timeineSortedList,
            ...treatmentPlans.treatmentplans.map((value) => {
                value['timeline_type'] = 'treatmentplans';
                return value;
            })
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treatmentPlans])

    useEffect(() => {
        patientFiles && setTimeineSortedList([
            ...timeineSortedList,
            ...patientFiles.files.map((value) => {
                value['timeline_type'] = 'files';
                return value;
            })
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientFiles])

    useEffect(() => {
        callLogs && setTimeineSortedList([
            ...timeineSortedList,
            ...callLogs.telecalllogs.map((value) => {
                value['timeline_type'] = 'telecalllogs';
                value['uploadtime'] = value.createdat;
                return value;
            })
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callLogs])

    useEffect(() => {
        async function fetchData() {
            setTimeineSortedList([])
            switch (selectedFilter) {
                case 'Telecall logs': return await getTeleCallLog()
                case 'Treatment Plans': return await getTreatmentplans()
                case 'Prescriptions': return await getPrescriptions()
                case 'Files': return await getPatientFiles()
                default: return
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilter])


    const sortArray = (array) => {
        return array.sort((a, b) => (b.uploadtime > a.uploadtime) ? 1 : -1)
    }

    const handleChange = (value) => {
        setTimeLineFilter(value.target.value)
    }
    return (

        <>
            <SelectComp
                options={timelineFilterOptions.map((value) => {
                    return {
                        label: value,
                        value: value
                    }
                })}
                selected={selectedFilter}
                handleChange={handleChange}
                module="timeline"
            />
            <div>
                {
                    sortArray(timeineSortedList).map((value) => {
                        return (
                            <TimeLineComp
                                key={value.uploadtime}
                                type={value.timeline_type}
                                data={value}
                            />
                        )
                    })
                }
                {
                    timeineSortedList && !timeineSortedList.length && <div className="pt-5">
                        No data available
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    prescriptionDetails: state.Patient.timeLineBoard.prescriptionDetails,
    treatmentPlans: state.Patient.timeLineBoard.treatmentPlans,
    patientFiles: state.Patient.timeLineBoard.patientFiles,
    callLogs: state.Patient.timeLineBoard.teleCallLogs,
    selectedFilter: state.Patient.timeLineBoard.selectedFilter
})

const mapDispatchToProps = {
    getPrescriptions,
    getTreatmentplans,
    getPatientFiles,
    getTeleCallLog,
    setTimeLineFilter
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeLineCont)