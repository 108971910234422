import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import io from "socket.io-client";
import {
  setSocketConnection,
  setVideoPayload,
  setPrevChatData,
  setUserChatHistory,
  newMessageRecieved,
} from "../../modules/Socket/action";
import { logout } from "../../modules/Auth/Login/action";
import { showSnackbar } from "../../modules/Snackbar/action";
import { isIosSupported } from "../../utils/helper";

let mSocket;

export const connectScketIfReq = () => {};

const Socket = (props) => {
  const dispatch = useDispatch();
  const { clinicDetails, user, isLoggedIn } = useSelector(
    (state) => state.Patient.login
  );
  let { pathname } = useLocation();
  let params = useParams();

  const { apiSocket } = useSelector((state) => state.Patient.socketCon);

  useEffect(() => {
    if (user && isLoggedIn) {
      var payload = {
        patient: user && user.id,
        from: "PWA",
      };
      if (!apiSocket) {
        offAllListners();
        const ENDPOINT = "https://socketapi.kivihealth.com";
        mSocket = io(ENDPOINT);
        mSocket.on("connect", (data) => {
          dispatch(setSocketConnection(mSocket));
          mSocket.emit("patientCreateConnection", payload);
          if (pathname.indexOf("chatbox") === 1) {
            let doctorData;
            if (params && params.doctorId) {
              doctorData =
                clinicDetails &&
                clinicDetails.consultants.filter(
                  (value) => value.id.toString() === params.doctorId
                )[0];
            }
            let roomname =
              "kivi-" + (doctorData && doctorData.id) + "-" + (user && user.id);

            var roomdata = {
              from: "kivi",
              data: {
                roomname: roomname,
                doctor: {
                  id: doctorData && doctorData.id,
                  name: doctorData && doctorData.name,
                },
              },
            };
            roomdata = JSON.stringify(roomdata);
            if (apiSocket) {
              apiSocket.emit("joinroom", roomdata);
            }
          }
        });

        mSocket.on("drip", (data) => {
          mSocket.emit("drop", "drop");
        });
        mSocket.on("event_error", (err) => {});
        mSocket.on("error", (err) => {});

        mSocket.on("receivePatientsConversion", function (data) {
          dispatch(setPrevChatData(data.chatdata));
        });
        mSocket.on("receiveMessageData", (data) => {
          let textHistory = data.chatdata.slice(0).reverse();
          dispatch(setUserChatHistory(textHistory));
        });
        mSocket.on("receiveMessage", (data) => {
          dispatch(newMessageRecieved(data));
        });
        mSocket.on("multiplelogin", (data) => {
          dispatch(
            showSnackbar({
              status: 0,
              message: "You are already logged into another device",
            })
          );
          dispatch(logout(data));
        });
        mSocket.on("deliverysuccess", (data) => {});

        mSocket.on("startVideoSession", (data) => {
          localStorage.setItem("videoCall", JSON.stringify(data));
          async function manageotificaton() {
            showNotifications(data);
            dispatch(setVideoPayload(data));
          }
          manageotificaton();
        });
        mSocket.on("disconnectVideoCall", (data) => {
          localStorage.removeItem("videoCall");
          dispatch(setVideoPayload(null));
        });
        mSocket.on("disconnect", (data) => {
          offAllListners();
          dispatch(setSocketConnection(null));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoggedIn]);

  const offAllListners = () => {
    if (apiSocket) {
      mSocket.off("connect");
      mSocket.off("receivePatientsConversion");
      mSocket.off("deliverysuccess");
      mSocket.off("receiveMessageData");
      mSocket.off("receiveMessage");
      mSocket.off("drip");
      mSocket.off("event_error");
      mSocket.off("error");
      mSocket.off("startVideoSession");
      mSocket.off("disconnectVideoCall");
    }
  };

  const showNotifications = (message) => {
    var options = {
      body: "Doctor is calling, please join the call",
      vibrate: [200, 100, 200, 100, 200, 100, 200],
      icon: "./kividoc.png",
      dir: "ltr",
    };
    if (isIosSupported()) return new Notification(message.doctor.name, options);
  };

  return <></>;
};

export default Socket;
