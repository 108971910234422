import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as firebase from "firebase";
import "firebase/messaging";
import "firebase/firestore/memory";

import {
  getFirebaseAdminData,
  setPatientFirebaseId,
} from "../../modules/PushNotifications/action";
import { setVideoPayload } from "../../modules/Socket/action";
import { isIosSupported } from "../../utils/helper";

let messaging;

const PushNotifications = ({
  getFirebaseAdminData,
  setPatientFirebaseId,
  firebaseAdminData,
  isLogged,
}) => {
  const [isFirebase, setFirebase] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (firebaseAdminData && isLogged && !isFirebase) {
        if (isIosSupported()) {
          if (firebase.messaging.isSupported()) {
            firebase.initializeApp(firebaseAdminData);
            messaging = firebase.messaging();
            setFirebase(true);
            notifyTokenGenerate();
          }
        }
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseAdminData, setPatientFirebaseId, isLogged]);

  const notifyTokenGenerate = async () => {
    try {
      await messaging.requestPermission();
      const token = await messaging.getToken();
      localStorage.setItem("notification-token", token);
      setPatientFirebaseId(token);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("notification-token")) {
        setPatientFirebaseId(localStorage.getItem("notification-token"));
      }
      if (isFirebase && !localStorage.getItem("notification-token")) {
        notifyTokenGenerate();
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPatientFirebaseId]);

  useEffect(() => {
    if (!firebaseAdminData) {
      getFirebaseAdminData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFirebaseAdminData, isLogged]);

  useEffect(() => {
    if (isFirebase) {
      messaging.onMessage(async (message) => {
        var pushMesssage = JSON.parse(message.data.payload);
        var messageType = message.data.type;
        var notificationTitle = "Doctor";
        var notificationOptions = {};
        if (messageType === "video") {
          localStorage.setItem("videoCall", JSON.stringify(pushMesssage));
          dispatch(setVideoPayload(pushMesssage));
          notificationTitle = pushMesssage.doctor.name;
          notificationOptions = {
            body: "Doctor is calling, please join the call",
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            icon: "./kividoc.png",
          };
        } else {
          notificationOptions = {
            body: pushMesssage.message,
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            icon: "./kividoc.png",
          };
        }

        const reg = await navigator.serviceWorker.getRegistration();

        return reg.showNotification(notificationTitle, notificationOptions);
      });
    }
  }, [dispatch, isFirebase]);

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    firebaseAdminData: state.Patient.pushNotification.firebaseAdminData,
    isLogged: state.Patient.login.isLoggedIn,
  };
};
const mapDispatchToProps = {
  getFirebaseAdminData,
  setPatientFirebaseId,
};

export default connect(mapStateToProps, mapDispatchToProps)(PushNotifications);
