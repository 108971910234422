import {
    SET_SIGNUP_VIA_ACTION,
    SET_USER_PASSWORD,
    OTP_VERIFICATION,
    REGISTER_USER
} from "./action";

const userDefault = {
    signupViaCode: null,
    otpVerificationData: null,
    registerUser: null
};

export default function signupReducer(state = userDefault, action) {
    const { type, data } = action;
    switch (type) {
        case SET_SIGNUP_VIA_ACTION:
            return {
                ...state,
                signupViaCode: data
            }
        case SET_USER_PASSWORD:
            return {
                ...state,
                signupViaCode: null
            }
        case OTP_VERIFICATION:
            return {
                ...state,
                otpVerificationData: data
            }
        case REGISTER_USER:
            return {
                ...state,
                registerUser: data
            }
        default:
            return state;
    }
}
