export const ACTIVE_DISABLE_OVERLAY = 'ACTIVE_DISABLE_OVERLAY'

export const activateDisableOverlay = (data) => ({
    type: ACTIVE_DISABLE_OVERLAY,
    data: data
});


export const activeOverlay = () => {
    return dispatch => {
        dispatch(activateDisableOverlay(true))
    }
}
export const disableOverlay = () => {
    return dispatch => {
        dispatch(activateDisableOverlay(false))
    }
}