import React from "react";
import { connect } from "react-redux";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { getAppointmentList } from "../../modules/Appointment/AppointmentBoard/action";
import { useDispatch } from "react-redux";
import { logout } from "../../modules/Auth/Login/action";
import { showSnackbar } from "../../modules/Snackbar/action";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import ShareIcon from "@material-ui/icons/Share";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import CropFreeIcon from "@material-ui/icons/CropFree";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PatientHeaderComp from "../../../components/PatientHeaderComp";
import UserProfile from "./components/UserProfile";
import { Link } from "react-router-dom";
import PaymentIcon from "@material-ui/icons/Payment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    margin: "auto",
    cursor: "pointer",
    marginTop: "2rem",
    borderRadius: "5px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icons: {
    color: "#53b0cf",
  },
  mainRoot: {
    marginTop: "5rem",
    paddingTop: "0.5rem",
  },
  link: {
    color: "black",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
}));

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(logout());
  };

  const underDevelopment = () => {
    dispatch(
      showSnackbar({
        status: 0,
        message: "Under Development",
      })
    );
  };
  return (
    <div className={classes.mainRoot}>
      <PatientHeaderComp heading={<UserProfile />} />
      <List className={classes.root}>
        <ListItem button disabled onClick={underDevelopment}>
          <ListItemIcon>
            <FitnessCenterIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="Health Parameters" />
        </ListItem>
        <Divider variant="inset" component="li" style={{ width: "70%" }} />
        <ListItem button disabled onClick={underDevelopment}>
          <ListItemIcon>
            <NoteAddIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="Medicine" />
        </ListItem>
        <Divider variant="inset" component="li" style={{ width: "70%" }} />
        <ListItem button disabled onClick={underDevelopment}>
          <ListItemIcon>
            <LocalHospitalIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="Clinic Profile" />
        </ListItem>
        <Divider variant="inset" component="li" style={{ width: "70%" }} />

        <ListItem button disabled onClick={underDevelopment}>
          <ListItemIcon>
            <CropFreeIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="QR Scanner" />
        </ListItem>
        <Divider variant="inset" component="li" style={{ width: "70%" }} />

        <ListItem button disabled onClick={underDevelopment}>
          <ListItemIcon>
            <NotificationsIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>
        <ListItem component={Link} to="/paymentLog" className={classes.link}>
          <ListItemIcon>
            <PaymentIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary={" Payment Log"} />
        </ListItem>
      </List>
      <List className={classes.root}>
        <ListItem
          component={Link}
          to="/changePassword"
          className={classes.link}
        >
          <ListItemIcon>
            <LockOpenIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="Change password" />
        </ListItem>
        <Divider variant="inset" component="li" style={{ width: "70%" }} />
        <ListItem button disabled onClick={underDevelopment}>
          <ListItemIcon>
            <LiveHelpIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItem>
        <Divider variant="inset" component="li" style={{ width: "70%" }} />
        <ListItem button disabled onClick={underDevelopment}>
          <ListItemIcon>
            <ShareIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="Share App" />
        </ListItem>
        <Divider variant="inset" component="li" style={{ width: "70%" }} />

        <ListItem button onClick={logoutUser}>
          <ListItemIcon>
            <ExitToAppIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>

      <br />
      <p className="small" style={{ color: "#b8b8b8" }}>
        v1.0.2
      </p>
      <br />
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.Patient.appointmentBoard.appointmentList,
  bookedAppointment: state.Patient.appointmentBoard.bookedAppointment,
});

const mapDispatchToProps = {
  getAppointmentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
