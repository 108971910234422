import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material UI
import FaceIcon from "@material-ui/icons/Face";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import HomeIcon from "@material-ui/icons/Home";
import OpacityIcon from "@material-ui/icons/Opacity";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import {
  makeStyles,
  Paper,
  Avatar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

// Components
import InputComp from "../../../components/InputComp";
import DatePickerComp from "../../../components/DatePickerComp";
import ButtonComp from "../../../components/ButtonComp";
import SelectComp from "../../../components/SelectComp";

// Common Module
import {
  getUserArea,
  getUserCity,
  setEditprofile,
} from "../../modules/Common/action";

// utils
import { formatDate } from "../../utils/helper";
import { bloodGroup } from "../../utils/constant";

// Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    margin: "auto",
    marginTop: "3rem",
    borderRadius: "5px",
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#000",
    },
  },
  genderroot: {
    width: "100%",
    paddingLeft: "2rem",
    marginTop: "1rem",
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#26a0c9",
    },
  },
  smallText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  headerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  profilePic: {
    height: "80px",
    width: "80px",
    marginTop: "-10%",
    "& svg": {
      height: "70%",
      width: "70%",
    },
  },
  paperRoot: {
    background:
      "linear-gradient(to bottom, #24a0cb 0%, #1263e6 20%, transparent 20%)",
    paddingTop: "30px",
  },
}));

const EditProfileCont = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, clinicDetails } = useSelector((state) => state.Patient.login);
  const { city, area } = useSelector((state) => state.Patient.commonData);

  const [formData, setFormData] = useState({
    clinicid: clinicDetails && clinicDetails.clinic && clinicDetails.clinic.id,
    dob: user && formatDate(new Date(user.dob)),
    doctorid: "",
    email: user && user.email,
    contactno: user && user.contactno,
    name: user && `${user.name}`,
    mname: user && `${user.mname}`,
    lname: user && `${user.lname}`,
    address: "",
    city: "",
    area: "",
    appointmentfrom: "PWA",
    medicalhistory: "",
    otherhistory: "",
    gender: "",
  });

  const handleChange = (event) => {
    var { name, value, type } = event.target;
    if (type === "checkbox") {
      value = event.target.checked ? 1 : 0;
    }
    if (name === "city") {
      const payload = {
        clinicid: clinicDetails && clinicDetails.clinic.id,
        cityid: value,
      };
      dispatch(getUserArea(payload));
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    dispatch(setEditprofile(formData));
  };

  // console.log(city, area);
  useEffect(() => {
    if (clinicDetails && clinicDetails.clinic) {
      dispatch(getUserCity(clinicDetails.clinic));
    }
  }, [dispatch, clinicDetails]);

  return user && clinicDetails ? (
    <div className={classes.paperRoot}>
      <Paper className={classes.root}>
        <div className="d-flex justify-content-center">
          <Avatar src={user.profilepic} className={classes.profilePic}>
            <FaceIcon />
          </Avatar>
        </div>
        <InputComp
          label="First Name"
          name="name"
          type="text"
          onChange={handleChange}
          value={formData.name}
        >
          <PersonIcon />
        </InputComp>
        <InputComp
          label="Middle Name"
          name="mname"
          type="text"
          onChange={handleChange}
          value={formData.mname}
        >
          <PersonIcon />
        </InputComp>
        <InputComp
          label="Last Name"
          name="lname"
          type="text"
          onChange={handleChange}
          value={formData.lname}
        >
          <PersonIcon />
        </InputComp>
        {formData.dob.length && (
          <DatePickerComp
            handleChange={handleChange}
            name="dob"
            value={formData.dob}
            label={"Select DOB"}
          />
        )}
        <InputComp
          label="Email"
          name="email"
          type="text"
          onChange={handleChange}
          value={formData.email}
        >
          <EmailIcon />
        </InputComp>
        <InputComp
          label="Contact No"
          name="contactno"
          type="text"
          onChange={handleChange}
          value={formData.contactno}
        >
          <PhoneAndroidIcon />
        </InputComp>

        <InputComp
          label="Address"
          name="address"
          type="text"
          onChange={handleChange}
          value={formData.address}
        >
          <HomeIcon />
        </InputComp>

        <br />
        <SelectComp
          options={
            city &&
            city.map((value) => {
              return {
                label: value.name,
                value: value.id,
              };
            })
          }
          handleChange={handleChange}
          label="City"
          name="city"
        >
          <LocationCityIcon />
        </SelectComp>
        <br />
        <SelectComp
          options={
            area &&
            area.map((value) => {
              return {
                label: value.name,
                value: value.id,
              };
            })
          }
          handleChange={handleChange}
          label="Area"
          name="area"
          isDisable={
            formData.city && formData.city.toString().length ? false : true
          }
        >
          <HomeWorkIcon />
        </SelectComp>
        <br />
        <SelectComp
          options={bloodGroup.map((value) => {
            return {
              label: value,
              value: value,
            };
          })}
          handleChange={handleChange}
          label="Select blood Group"
          name="bloodgroup"
        >
          <OpacityIcon />
        </SelectComp>
        <FormControl className={classes.genderroot} component="fieldset">
          <RadioGroup
            row
            aria-label="gender"
            name="gender"
            value={formData.gender ? formData.gender : "1"}
            onChange={handleChange}
          >
            <FormControlLabel value="1" control={<Radio />} label="Male" />
            <FormControlLabel value="0" control={<Radio />} label="Female" />
          </RadioGroup>
        </FormControl>
      </Paper>

      <div className="d-flex justify-content-center">
        <ButtonComp label="Submit" onClick={handleSubmit} />
      </div>
      <br />
      <br />
    </div>
  ) : (
    <></>
  );
};

export default EditProfileCont;
