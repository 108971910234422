import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "95%",
    margin: "auto",
    borderRadius: "30px",
    bottom: "20px",
    position: "fixed",
    zIndex: 2,
    maxWidth: "485px",
  },
  overlayBackground: {
    zIndex: 1,
    position: "fixed",
    background: "#efeeec",
    height: "70px",
    width: "100%",
    bottom: 0,
    maxWidth: "500px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color: "rgba(0, 0, 0, 0.54)",
  },
  sendIconButton: {
    padding: 10,
    color: "#26a0c9",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  fileupload: {
    display: "none",
  },
}));

export default function ChatInputBoxComp({
  sendMessage,
  drDetails,
  manageCameraState,
}) {
  const classes = useStyles();
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubmit = (event) => {
    if (message && message.length) {
      sendMessage("text", event, message, drDetails);
      setMessage("");
    }
  };

  const onFileChange = async (event) => {
    // Update the state
    const data = event.target.files[0];
    var reader = new FileReader();
    await reader.readAsDataURL(data);
    reader.onload = function () {
      sendMessage("file", event, reader.result, drDetails, data);
    };
  };

  return (
    <div className="d-flex justify-content-center">
      <Paper component="form" className={classes.root}>
        <IconButton
          className={classes.iconButton}
          aria-label="Camera"
          onClick={() => manageCameraState()}
        >
          <CameraAltIcon />
        </IconButton>
        <label htmlFor="img_file" className="mb-0">
          <div className={classes.iconButton}>
            <LinkIcon />
          </div>
          <input
            type="file"
            id="img_file"
            name="img_file"
            className={classes.fileupload}
            accept="image/x-png,image/gif,image/jpeg, image/jpg, application/pdf"
            onChange={onFileChange}
          />
        </label>
        <InputBase
          className={classes.input}
          placeholder="Write a message..."
          inputProps={{ "aria-label": "Write a message..." }}
          onChange={handleChange}
          value={message}
          onKeyPress={(event) =>
            event.key === "Enter" ? handleSubmit(event) : null
          }
        />
        <IconButton
          className={classes.sendIconButton}
          onClick={(e) => handleSubmit(e)}
          aria-label="Send"
        >
          <SendIcon />
        </IconButton>
      </Paper>
      <div className={classes.overlayBackground}></div>
    </div>
  );
}
