import CryptoJS from "crypto-js";
import { BASE_URL } from "../../utils/apiHandler";
import { globalApiHandler } from "../../utils/globalApiHandler";

import { activeOverlay, disableOverlay } from "../Overlay/action";
import { showSnackbar } from "../Snackbar/action";

export const GET_AREA = "GET_AREA";
export const GET_CITY = "GET_CITY";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

const getAreaAction = (data) => ({
  type: GET_AREA,
  data: data,
});
const getCityAction = (data) => ({
  type: GET_CITY,
  data: data,
});
const setEditprofileAction = (data) => ({
  type: EDIT_PROFILE,
  data: data,
});
const changeUserpasswordAction = (data) => ({
  type: CHANGE_PASSWORD,
  data: data,
});

export const getUserCity = (clinic) => async (dispatch) => {
  dispatch(activeOverlay());
  try {
    var clinicID = JSON.stringify({ clinicid: clinic.id });
    var url = `${BASE_URL}/getallcities`;
    const res = await globalApiHandler(url, "post", clinicID);
    var data = res.result.cities;
    dispatch(getCityAction(data));
    dispatch(disableOverlay());
  } catch (err) {}
};

export const getUserArea = (payload) => async (dispatch) => {
  dispatch(activeOverlay());
  try {
    var url = `${BASE_URL}/getallareas`;
    const res = await globalApiHandler(url, "post", payload);
    const data = res.result;
    if (res.status) {
      dispatch(getAreaAction(data.areas));
    } else {
      dispatch(getAreaAction([]));
    }
    dispatch(disableOverlay());
  } catch (err) {}
};

export const setEditprofile = (payload) => async (dispatch) => {
  dispatch(activeOverlay());
  try {
    var url = `${BASE_URL}/editprofile`;
    const res = await globalApiHandler(url, "post", payload);
    const data = res.result;
    if (res.status) {
      dispatch(setEditprofileAction(data.areas));
    } else {
      dispatch(setEditprofileAction([]));
    }
    dispatch(disableOverlay());
  } catch (err) {}
};

export const changeUserpassword = (payload) => async (dispatch) => {
  dispatch(activeOverlay());
  var passwordHash = CryptoJS.SHA1(payload.password);
  var newpasswordHash = CryptoJS.SHA1(payload.newpassword);

  var data = JSON.stringify({
    password: CryptoJS.enc.Hex.stringify(passwordHash),
    newpassword: CryptoJS.enc.Hex.stringify(newpasswordHash),
  });
  try {
    var url = `${BASE_URL}/changepassword`;
    const res = await globalApiHandler(url, "post", data);
    if (res.status) {
      dispatch(changeUserpasswordAction(res));
    } else {
      dispatch(showSnackbar(res));
    }
    dispatch(disableOverlay());
  } catch (err) {}
};
