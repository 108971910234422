import { combineReducers } from "redux";

import login from "./Patient/modules/Auth/Login/reducer";
import signup from "./Patient/modules/Auth/Signup/reducer";
import timeLineBoard from "./Patient/modules/TimeLine/TimeLineBoard/reducer";
import appointmentBoard from "./Patient/modules/Appointment/AppointmentBoard/reducer";
import overlay from "./Patient/modules/Overlay/reducer";
import snackbar from "./Patient/modules/Snackbar/reducer";
import termsConditon from "./Patient/modules/TermsConditions/reducer";
import pushNotification from "./Patient/modules/PushNotifications/reducer";
import socketCon from "./Patient/modules/Socket/reducer";
import commonData from "./Patient/modules/Common/reducer";
import paymentLog from "./Patient/modules/Common/PaymentLog/reducer";
import payment from "./Patient/modules/Payment/reducer";

const Patient = combineReducers({
  login,
  signup,
  socketCon,
  timeLineBoard,
  appointmentBoard,
  snackbar,
  overlay,
  termsConditon,
  pushNotification,
  commonData,
  paymentLog,
  payment,
});

export default combineReducers({
  Patient,
});
