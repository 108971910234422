import React from 'react';
import UserProfileCont from '../../../containers/MyAccount/UserProfileCont';

function UserProfile() {

    return (
        <>
            <UserProfileCont />
        </>
    );
}

export default UserProfile;
