import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { connect } from 'react-redux';

const PublicLayout = (props) => {

    const route = props.route
    const Component = props.component;
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="mb-5">
                <Component route={route} />
            </main>
        </>
    )
}

const mapStateToProps = (state) => ({
});

export default connect(
    mapStateToProps,
    {}
)(PublicLayout);

