import React from 'react';

import LogoComp from "../../../../components/LogoComp";
import LoginFormCont from "../../../containers/Auth/LoginFormCont"

function Login() {
  return (
    <>
      <LogoComp />
      <LoginFormCont />
    </>
  );
}

export default Login;
