import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { formatDate } from "../../Patient/utils/helper";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(1),
    minWidth: 120,
    // width: '90%',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  Icon: {
    color: "#26a0c9",
    marginRight: 5,
  },
}));
export default function DatePickerComp(props) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(props.value);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.handleChange({
      target: { name: props.name, value: formatDate(date) },
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        className={classes.formControl}
      >
        <DateRangeIcon className={classes.Icon} />
        <DatePicker
          margin="normal"
          id="date-picker-dialog"
          label={props.label}
          format="yyyy-dd-MM"
          value={selectedDate}
          disablePast={props.isPastEnable ? props.isPastEnable : false}
          onChange={handleDateChange}
          style={{ flex: 1 }}
          disabled={props.isDisable ? props.isDisable : false}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
