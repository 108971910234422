import { createBrowserHistory } from 'history'
import { store } from '.././../index'
import { loginFailure } from "../modules/Auth/Login/action";
import axios from "axios";

const history = createBrowserHistory();

let accessToken = {}


export const getLocalStorageUser = () => {
    return localStorage.getItem("token");
}

export const globalApiHandler = (url, method, data) => {
    accessToken = getLocalStorageUser();

    const config = {
        method: method,
        url: url,
        headers: {
            'Content-Type': "application/json",
            'Authorization': accessToken
        },
        data: data
    };
    return axios(config).then((res) => {
        if (!res.status) {
            localStorage.removeItem('token')
            history.push("/login");
            res['message'] = "Session Expired, Please login again."
            store.dispatch(loginFailure(res))
            return;
        }
        return res.data;
    }).catch((err) => {
        return err;
    });
}
