import { BASE_URL } from "../../../utils/apiHandler";
import { globalApiHandler } from "../../../utils/globalApiHandler";
import { activeOverlay, disableOverlay } from "../../Overlay/action";
import { showSnackbar } from "../../Snackbar/action";

export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const GET_TIME_SLOTS = "GET_TIME_SLOTS";
export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT";

const getAppointmentListAction = (data) => ({
  type: GET_APPOINTMENT,
  data: data,
});

const getTimeSlotsAction = (data) => ({
  type: GET_TIME_SLOTS,
  data: data,
});
export const bookAppointmentAction = (data) => ({
  type: BOOK_APPOINTMENT,
  data: data,
});

export const getAppointmentList = () => async (dispatch) => {
  dispatch(activeOverlay());
  var data = "";
  const url = `${BASE_URL}/getmyappointments`;

  try {
    const res = await globalApiHandler(url, "post", data);
    const result = res.result;
    dispatch(getAppointmentListAction(result));
    dispatch(disableOverlay());
  } catch (err) {}
};

export const getTimeSlots = (details) => async (dispatch) => {
  var data = JSON.stringify({
    clinicid: details.clinicid,
    date: details.date,
    doctorid: details.doctorId,
  });
  const url = `${BASE_URL}/clinicslots`;

  try {
    const res = await globalApiHandler(url, "post", data);
    const result = res.result;
    dispatch(getTimeSlotsAction(result));
  } catch (err) {}
};
export const bookAppointment = (details) => async (dispatch) => {
  dispatch(activeOverlay());
  var data = JSON.stringify(details);
  const url = `${BASE_URL}/bookappointment`;

  try {
    const res = await globalApiHandler(url, "post", data);
    dispatch(showSnackbar(res));
    dispatch(bookAppointmentAction(res));
    dispatch(disableOverlay());
  } catch (err) {}
};
