import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(1),
    minWidth: 120,
    // width: '90%',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiSelect-select": {
      textAlign: "left",
    },
  },
  formControlTimeLine: {
    padding: theme.spacing(1),
    minWidth: 120,
    // width: '90%',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiSelect-select": {
      textAlign: "center",
    },
  },
  selectBox: {
    flex: 1,
  },
  selectEmpty: {
    flex: 1,
    marginTop: theme.spacing(2),
    background: "#fff",
    borderRadius: "22px",
    padding: "3px",
    textAlign: "center",
    "&:before, &:after,& .MuiSelect-icon": {
      display: "none",
    },
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
  },
  Icon: {
    color: "#26a0c9",
    marginRight: 5,
  },
}));

export default function SelectComp(props) {
  const classes = useStyles();
  const [selected, changeSelection] = React.useState(
    props.selected ? props.selected : ""
  );

  const handleChange = (event) => {
    changeSelection(event.target.value);
    props.handleChange(event);
  };
  //   console.log(props);

  return (
    <FormControl
      className={
        props.module === "timeline"
          ? classes.formControlTimeLine
          : classes.formControl
      }
      disabled={props.isDisable ? props.isDisable : false}
    >
      {props.children && <div className={classes.Icon}>{props.children}</div>}
      <Select
        value={selected}
        onChange={(e) => handleChange(e)}
        displayEmpty
        className={
          props.module === "timeline" ? classes.selectEmpty : classes.selectBox
        }
        inputProps={{ "aria-label": props.label }}
        name={props.name}
      >
        {!props.selected && <MenuItem value={""}>{props.label}</MenuItem>}
        {props.options &&
          props.options.map((data) => {
            return (
              <MenuItem value={data.value} key={data.value}>
                {data.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
