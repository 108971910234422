import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

import InputComp from "../../../components/InputComp";
import ButtonComp from "../../../components/ButtonComp";

import { Link } from 'react-router-dom';
import { setSignupViaCode } from '../../modules/Auth/Signup/action';
import SetPasswordCont from './SetPasswordCont';
import VerifyOtpCont from './VerifyOtpCont';
import { showSnackbar } from '../../modules/Snackbar/action';

const useStyles = makeStyles((theme) => ({
    loginForm: {
        background: "#ffffff",
        minHeight: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    forgotLink: {
        color: "#26a0c9"
    }
}));

function SignUpCont() {
    const classes = useStyles();
    const dispatch = useDispatch()

    const [userCred, setUserCred] = useState({})
    const signupViaCode = useSelector(state => state.Patient.signup.signupViaCode)

    const handleInputChange = e => {
        const { name, value } = e.target
        setUserCred({ ...userCred, [name]: value })
    }

    const handleClick = () => {
        if (userCred.contactno && userCred.contactno.length &&
            userCred.referralcode && userCred.referralcode.length) {
            dispatch(setSignupViaCode(userCred));
            // setUserCred();
        } else {
            dispatch(showSnackbar({
                status: 0, message: "All fields are mandetory"
            }))
        }
    }

    if (signupViaCode && signupViaCode.patientauthcode) {
        return <SetPasswordCont />
    }
    if (signupViaCode && signupViaCode.doctorauthcode) {
        return <VerifyOtpCont contactno={userCred.contactno} />
    }
    return (
        <div className="mt-3 container">
            <div className={`${classes.loginForm} row`}>
                <div className="col-10">
                    <h2 className="mb-3">Sign Up</h2>
                    <p className="small">* Please enter your registered mobile number & authentication code
                    recieved from your doctor t validate your profile
                    </p>
                    <div>
                        <InputComp
                            label="Mobile Number *"
                            name="contactno"
                            type="text"
                            onChange={handleInputChange}
                            value={userCred.contactno}
                        >
                            <PhoneAndroidIcon />
                        </InputComp>
                        <InputComp
                            label="Authentication Code *"
                            name="referralcode"
                            type="text"
                            onChange={handleInputChange}
                            value={userCred.referralcode}
                        >
                            <FingerprintIcon />
                        </InputComp>
                        <ButtonComp
                            label="Validate"
                            onClick={handleClick}
                        />
                        <div>Already have an account ? <Link to="/login">Login</Link></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpCont;
