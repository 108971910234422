import React from 'react'
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Paper, Button } from '@material-ui/core';

import {
    setaccepttermsandconditions
} from '../../modules/TermsConditions/action'


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        background: "#ffffff"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: "#444",
        fontWeight: "700"
    },
    mainDialog: {
        background: "#efeeec"
    },
    paperRoot: {
        padding: "1em",
        textAlign: 'justify',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: "#000",
        "& div": {
            marginBottom: "0.5rem"
        },
        "& button, & button:hover": {
            marginTop: "0.5rem",
            fontSize: "16px",
            fontWeight: 700,
            background: "#ff7e66",
            border: "1px solid #ff7e66",
            color: "#fff"
        }
    }
}));


const TermsConditionsCont = ({
    setaccepttermsandconditions
}) => {

    const classes = useStyles();
    const acceptTermsConditions = () => {
        setaccepttermsandconditions()
    }

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Terms & Conditions
                </Typography>
                </Toolbar>
            </AppBar>
            <Paper className={classes.paperRoot} elevation={1}>
                <h4 className="text-center">User Agreement</h4>
                <div>By signing this form, I understand the following:</div>

                <div><b>1.</b> I understand that the laws that protect privacy and
                the confidentiality of medical information also apply to telemedicine,
                and that no information obtained in the use of telemedicine which identifies
                me will be disclosed to researchers or other entities without my consent.</div>
                <div><b>2.</b> I understand that I have the right to withhold or withdraw my consent
                to the use of telemedicine in the course of my care at any time, without
                affecting my right to future care or treatment.</div>
                <div><b>3.</b> I understand that I have the right to inspect all information
                obtained and recorded in the course of a telemedicine interaction,
                and may receive copies of this information from the clinic.</div>
                <div><b>4.</b> I understand that a variety of alternative methods of medical
                care may be available to me, and that I may choose one or more of these
                at any time. My Doctor has explained the alternatives to my satisfaction.</div>
                <div><b>5.</b> I understand that telemedicine may involve electronic communication
                of my personal medical information to other medical practitioners who may
                be located in other areas, including out of state.</div>
                <div><b>6.</b> I understand that it is my duty to inform my doctor of
                electronic interactions regarding my care that I may have with
                other healthcare providers.</div>
                <div><b>7.</b> I understand that I may expect the anticipated benefits
                from the use of telemedicine in my care, but that no results can be
                guaranteed or assured. Patient Consent To The Use of Telemedicine
                </div>

                <div>I have read and understand the information provided above
                regarding telemedicine, have discussed it with my Doctor
                or such assistants as may be designated, and all of my
                questions have been answered to my satisfaction. I hereby
                give my informed consent for the use of telemedicine in
                my medical care. I hereby authorize Clinic Name  to use
                telemedicine in the course of my diagnosis and treatment.
                </div>
                <Button onClick={acceptTermsConditions}>Accept & Sign up</Button>
            </Paper>
        </>
    )
}

const mapStateToProps = state => {
    return {
    }
}
const mapDispatchToProps = {
    setaccepttermsandconditions
}


export default connect(mapStateToProps, mapDispatchToProps)(TermsConditionsCont)