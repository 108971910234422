
import {
    SET_PATIENT_FIREBASE_ID,
    GET_FIREBASE_ADMIN_DATA
} from './action';

const initialState = {
    setFirebaseId: null,
    firebaseAdminData: null
}

export default function pushNotificationReducer(state = initialState, action) {
    const { type, data } = action;
    switch (type) {
        case SET_PATIENT_FIREBASE_ID:
            return {
                ...state,
                setFirebaseId: data,
            };
        case GET_FIREBASE_ADMIN_DATA:
            return {
                ...state,
                firebaseAdminData: data,
            };
        default:
            return state;
    }
}