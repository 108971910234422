import { BASE_URL } from "../../../utils/apiHandler";
import { globalApiHandler } from "../../../utils/globalApiHandler";

import { activeOverlay, disableOverlay } from "../../Overlay/action";

export const PAYMENT_LOGS = "PAYMENT_LOGS";

const getPaymentLogsAction = (data) => ({
  type: PAYMENT_LOGS,
  data: data,
});

export const getPaymentLogs = (clinic) => async (dispatch) => {
  dispatch(activeOverlay());
  try {
    var clinicID = JSON.stringify({ clinicid: clinic.id });
    var url = `${BASE_URL}/paymentlogs`;
    const res = await globalApiHandler(url, "post", clinicID);
    var data = res.result;
    dispatch(getPaymentLogsAction(data.logs));
    dispatch(disableOverlay());
  } catch (err) {}
};
