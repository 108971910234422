import React from 'react';
import TermsConditionsCont from '../../containers/TermsConditions/TermsConditionsCont';

export default function TermsConditions() {
    return (
        <>
            <TermsConditionsCont />
        </>
    );
}
