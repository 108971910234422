import React from 'react';

import TimeLineCont from '../../containers/Timeline/TimeLineCont'
import PatientHeaderComp from '../../../components/PatientHeaderComp'

function Timeline() {



    return (
        <>
            <PatientHeaderComp heading="Timeline" />
            <TimeLineCont />
        </>
    );
}

export default Timeline;
