import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

import { IconButton, makeStyles, Zoom } from "@material-ui/core";
import { isIosSupported } from "../../Patient/utils/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  notifyIcon: {
    padding: 10,
    color: "red",
    background: "#ffffff14",
  },
  buttonEnable: {
    color: "#fff",
    background: "#1f98c0",
    "&  .Mui-disabled": {
      background: "#ccc",
    },
  },
}));

export default function EnableNotificationCheckComp() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleEnable = async () => {
    window.Notification.requestPermission().then((permission) => {});

    window.Notification.requestPermission((permission) => {});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialogBoxMethod = () => {
    return (
      <>
        <Zoom in={true} timeout={500}>
          <IconButton
            className={classes.notifyIcon}
            onClick={(e) => handleOpen(e)}
            aria-label="Send"
          >
            <NotificationImportantIcon />
          </IconButton>
        </Zoom>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Enable Notification
          </DialogTitle>
          <DialogContent>
            {isIosSupported() ? (
              <>
                <DialogContentText id="alert-dialog-slide-description">
                  For best experience enable notification setting for this site.
                  <br />
                  <br />
                  <span>
                    {" "}
                    <b>* Note: </b>
                    If you have blocked notifications in the first place. Please
                    go to the browser settings or click
                    <span>
                      {
                        <InfoOutlinedIcon
                          style={{ marginBottom: "-6px", marginLeft: "5px" }}
                        />
                      }
                    </span>{" "}
                    icon in url section to enable notifications.
                  </span>
                </DialogContentText>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleEnable}
                    variant="contained"
                    color="default"
                    className={classes.buttonEnable}
                    disabled={Notification.permission === "denied"}
                  >
                    Enable
                  </Button>
                </DialogActions>
              </>
            ) : (
              <DialogContentText id="alert-dialog-slide-description">
                Please use <b>Chrome</b> for better experiance
                {
                  <ThumbUpIcon
                    style={{ marginBottom: "-6px", marginLeft: "5px" }}
                  />
                }
              </DialogContentText>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {isIosSupported() ? (
        Notification.permission !== "granted" ? (
          dialogBoxMethod()
        ) : (
          <></>
        )
      ) : (
        dialogBoxMethod()
      )}
    </>
  );
}
