import { BASE_URL } from "../../utils/apiHandler";
import { globalApiHandler } from "../../utils/globalApiHandler";
import { disableOverlay, activeOverlay } from "../Overlay/action";
import { showSnackbar } from "../Snackbar/action";

export const SET_TERMS_CONDITIONS = "SET_TERMS_CONDITIONS";
export const IS_ACCEPTED_TERMS_CONDITIONS = "IS_ACCEPTED_TERMS_CONDITIONS";

const termsConditionsAction = (data) => ({
  type: SET_TERMS_CONDITIONS,
  data: data,
});
const getAcceptedTermsConditionsAction = (data) => ({
  type: IS_ACCEPTED_TERMS_CONDITIONS,
  data: data,
});

export const setaccepttermsandconditions = () => async (dispatch) => {
  dispatch(activeOverlay());
  var data = "";
  const url = `${BASE_URL}/setaccepttermsandconditions`;

  try {
    const res = await globalApiHandler(url, "post", data);
    dispatch(showSnackbar(res));
    dispatch(termsConditionsAction());
    dispatch(disableOverlay());
  } catch (err) {}
};
export const isAcceptedTermsConditons = () => async (dispatch) => {
  dispatch(activeOverlay());
  var data = "";
  const url = `${BASE_URL}/getaccepttermsandconditions`;

  try {
    const res = await globalApiHandler(url, "post", data);
    if (!res.status) {
      dispatch(showSnackbar(res));
    }
    dispatch(getAcceptedTermsConditionsAction(res));
    dispatch(disableOverlay());
  } catch (err) {}
};
