import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PersonIcon from '@material-ui/icons/Person';

import InputComp from "../../../components/InputComp";
import ButtonComp from "../../../components/ButtonComp";

import { registerUserSignup } from '../../modules/Auth/Signup/action';
import { showSnackbar } from '../../modules/Snackbar/action';
import { FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    loginForm: {
        background: "#ffffff",
        minHeight: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    genderroot: {
        width: '100%',
        paddingLeft: '2rem',
        marginTop: '1rem'
    }
}));

function RegisterUserCont({ contactno }) {
    const classes = useStyles();
    const dispatch = useDispatch()


    const signupViaCode = useSelector(state => state.Patient.signup.signupViaCode)
    const [userCred, setUserCred] = useState({
        clinicid: signupViaCode && signupViaCode.clinicid,
        contactno: contactno,
        email: '',
        password: ''
    })

    const handleInputChange = e => {
        const { name, value } = e.target
        setUserCred({ ...userCred, [name]: value })
    }

    const handleClick = () => {
        if (userCred.password !== userCred.rePass) {
            return (
                dispatch(showSnackbar({
                    status: 0, message: "Passwords are not equal, try again"
                }))
            )
        }
        if (userCred.password && userCred.password.length &&
            userCred.rePass && userCred.rePass.length &&
            userCred.patientname && userCred.patientname.length &&
            userCred.contactno && userCred.contactno.length &&
            userCred.rePass && userCred.rePass.length &&
            userCred.clinicid && userCred.clinicid.toString().length &&
            userCred.email && userCred.email.length) {
            dispatch(registerUserSignup(userCred));
        } else {
            dispatch(showSnackbar({
                status: 0, message: "All fields are mandetory"
            }))
        }
    }

    if (!signupViaCode) {
        return <Redirect to="/signup" />
    }

    return (
        <div className="mt-3 container">
            <div className={`${classes.loginForm} row`}>
                <div className="col-10">
                    <h2 className="mb-3">{signupViaCode ? signupViaCode.clinicname : "Register User"}</h2>
                    <div>
                        <InputComp
                            label="Patient Name *"
                            name="patientname"
                            onChange={handleInputChange}
                            value={userCred.patientname}
                            type="text"
                        >
                            <PersonIcon />
                        </InputComp>
                        <InputComp
                            label="Mobile Number *"
                            name="contactno"
                            type="text"
                            onChange={handleInputChange}
                            value={contactno}
                            isDisable={true}
                        >
                            <PhoneAndroidIcon />
                        </InputComp>
                        <FormControl className={classes.genderroot} component="fieldset">
                            <RadioGroup
                                row
                                aria-label="gender"
                                name="gender"
                                value={userCred.gender ? userCred.gender : "1"}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel
                                    value="1"
                                    control={<Radio color="secondary" />}
                                    label="Male" />
                                <FormControlLabel
                                    value="0"
                                    control={<Radio color="secondary" />}
                                    label="Female" />
                            </RadioGroup>
                        </FormControl>
                        <InputComp
                            label="Email *"
                            name="email"
                            type="email"
                            onChange={handleInputChange}
                            value={userCred.email}
                        >
                            <MailOutlineIcon />
                        </InputComp>
                        <InputComp
                            label="Password *"
                            name="password"
                            type="password"
                            onChange={handleInputChange}
                            value={userCred.password}
                        >
                            <LockOpenIcon />
                        </InputComp>
                        <InputComp
                            label="Confirm Password *"
                            name="rePass"
                            type="password"
                            onChange={handleInputChange}
                            value={userCred.rePass}
                        >
                            <LockOpenIcon />
                        </InputComp>
                        <ButtonComp
                            label="Validate"
                            onClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default RegisterUserCont;
