import React from 'react';

import logo from '../../assets/images/kivi_logo.jpg';

import './index.scss'

function LogoComp() {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center logopage">
        <div className="col-8 pt-3 pb-3">
          <img width="100%" height="50vh" src={logo} alt="Kivi" />
        </div>
      </div>
    </div>
  );
}



export default LogoComp;
