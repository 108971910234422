import axios from "axios";
import CryptoJS from "crypto-js";

import { BASE_URL, PUBLIC_TOKEN } from "../../../utils/apiHandler";
import { showSnackbar } from "../../Snackbar/action";
import { activeOverlay, disableOverlay } from "../../Overlay/action";
import {
  loginSuccess,
  getUserProfile,
} from "../Login/action";

import { isAcceptedTermsConditons } from "../../TermsConditions/action";
import { getFirebaseAdminData } from "../../PushNotifications/action";

export const SET_SIGNUP_VIA_ACTION = "SET_SIGNUP_VIA_ACTION";
export const SET_USER_PASSWORD = "SET_USER_PASSWORD";
export const REGISTER_USER = "REGISTER_USER";
export const OTP_VERIFICATION = "OTP_VERIFICATION";

export const setSignupViaCodeAction = (data) => ({
  type: SET_SIGNUP_VIA_ACTION,
  data: data,
});

const setUserPasswordAction = (data) => ({
  type: SET_USER_PASSWORD,
  data: data,
});

const otpVerificationAction = (data) => ({
  type: OTP_VERIFICATION,
  data: data,
});

const registerUserSignupAction = (data) => ({
  type: REGISTER_USER,
  data: data,
});

export const setSignupViaCode = (user) => async (dispatch) => {
  dispatch(activeOverlay());
  var data = JSON.stringify(user);
  var api = "patientauthviacode";
  const config = publicConfig(api, data);
  try {
    const res = await axios(config);
    let isSucces = res.status;
    if (isSucces) {
      const resData = res.data;
      const result = resData.result;
      if (!resData.status) {
        dispatch(showSnackbar(resData));
      } else if (result.access_token && result.patient) {
        dispatch(signupSuccessFull(result));
      } else {
        dispatch(setSignupViaCodeAction(result));
      }
      dispatch(disableOverlay());
    } else {
    }
  } catch (err) {
    let mesaage = {};
    if (err.response === undefined) {
      mesaage["error_description"] = "Service Unavailable";
    } else {
      mesaage["error_description"] = "Invalid Email or Password";
    }
    dispatch({ type: "SERVER_ERROR", data: mesaage });
    dispatch(disableOverlay());
  }
};

export const setUserPassword = (user) => async (dispatch) => {
  dispatch(activeOverlay());
  var hash = CryptoJS.SHA1(user.password);

  var data = JSON.stringify({
    patientid: user.patientid,
    email: user.patientid,
    password: CryptoJS.enc.Hex.stringify(hash),
  });
  var api = "setpassword";
  const config = publicConfig(api, data);
  try {
    const res = await axios(config);
    const resData = res.data;
    dispatch(showSnackbar(resData));
    if (resData.status) {
      const result = resData.result;
      dispatch(signupSuccessFull(result));
      dispatch(setUserPasswordAction());
    }
    dispatch(disableOverlay());
  } catch (err) {}
};

export const otpVerification = (user) => async (dispatch) => {
  dispatch(activeOverlay());
  var data = JSON.stringify(user);
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/otp/verifyotp`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axios(config);
    const resData = res.data;
    dispatch(showSnackbar(resData));
    if (resData.status) {
      dispatch(otpVerificationAction(resData));
    }
    dispatch(disableOverlay());
  } catch (err) {}
};
export const registerUserSignup = (user) => async (dispatch) => {
  dispatch(activeOverlay());
  var hash = CryptoJS.SHA1(user.password);

  var data = JSON.stringify({
    clinicid: user.clinicid,
    patientname: user.patientname,
    contactno: user.contactno,
    email: user.email,
    password: CryptoJS.enc.Hex.stringify(hash),
    gender: user.gender,
  });
  var api = "patientregistration";
  const config = publicConfig(api, data);
  try {
    const res = await axios(config);
    const resData = res.data;
    dispatch(showSnackbar(resData));
    if (resData.status) {
      const result = resData.result;
      dispatch(signupSuccessFull(result));
      dispatch(registerUserSignupAction(result));
    }
    dispatch(disableOverlay());
  } catch (err) {}
};

const signupSuccessFull = (result) => (dispatch) => {
  localStorage.setItem("token", result.access_token);
  dispatch(loginSuccess());
  dispatch(getUserProfile());
  dispatch(isAcceptedTermsConditons());
  dispatch(getFirebaseAdminData());
};

const publicConfig = (api, data) => {
  const config = {
    method: "post",
    url: `${BASE_URL}/${api}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: PUBLIC_TOKEN,
    },
    data: data,
  };
  return config;
};
