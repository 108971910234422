import React from 'react';

import SignUpCont from '../../../containers/Auth/SignUpCont';

function Signup() {

  return (
    <>
      <SignUpCont />
    </>
  );
}

export default Signup;
