import React from "react";

import PatientHeaderComp from "../../../components/PatientHeaderComp";
import AppointmentCont from "../../containers/Appointment/AppointmentCont";

function Appointments() {
  return (
    <>
      <PatientHeaderComp heading="Appointment" />
      <AppointmentCont />
    </>
  );
}

export default Appointments;
