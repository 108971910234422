import { GET_AREA, GET_CITY, EDIT_PROFILE, CHANGE_PASSWORD } from "./action";

const initialState = {
  area: null,
  city: null,
  editProfile: null,
  changePassword: null,
};

export default function commonReducder(state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case GET_AREA:
      return {
        ...state,
        area: data,
        changePassword: null,
      };
    case GET_CITY:
      return {
        ...state,
        city: data,
        changePassword: null,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        editProfile: data,
        changePassword: null,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: data,
      };
    default:
      return state;
  }
}
