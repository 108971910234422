import React, { useEffect } from "react";
import { connect } from "react-redux";

import { formatStartDatetime } from "../../utils/helper";
import CardComp from "../../../components/CardComp";
import { getPaymentLogs } from "../../modules/Common/PaymentLog/action";

const PaymentLogCont = ({ paymentlogs, getPaymentLogs, clinicDetails }) => {
  useEffect(() => {
    if (clinicDetails && clinicDetails.clinic)
      getPaymentLogs(clinicDetails.clinic);
  }, [clinicDetails, getPaymentLogs]);

  const sortArray = (array) => {
    return array.sort((a, b) =>
      new Date(b.datetime) > new Date(a.datetime) ? 1 : -1
    );
  };

  return (
    <div style={{ paddingTop: "35px" }}>
      {paymentlogs &&
        sortArray(paymentlogs).map((data, index) => {
          return (
            <CardComp
              data={data}
              heading={formatStartDatetime(data.datetime)}
              key={index}
            >
              <table className="w-100 text-left">
                <tbody>
                  <tr>
                    <td className="w-50">
                      <b>Doctor Name:</b>
                    </td>
                    <td>
                      <span>{data.docname}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Amount:</b>
                    </td>
                    <td>
                      <span> ₹ {data.amount}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Payment Status:</b>
                    </td>
                    <td>
                      <span>{data.status}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardComp>
          );
        })}
      <br />
      {paymentlogs && paymentlogs.length === 0 && (
        <div className="pt-5">No Payment log available</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinicDetails: state.Patient.login.clinicDetails,
  paymentlogs: state.Patient.paymentLog.paymentlogs,
});

const mapDispatchToProps = {
  getPaymentLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLogCont);
