import React from 'react';

import SetPasswordCont from '../../../containers/Auth/SetPasswordCont';

function SetPassword() {

    return (
        <>
            <SetPasswordCont />
        </>
    );
}

export default SetPassword;
