import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";

// Material UI
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CallIcon from "@material-ui/icons/Call";
import CardMembershipIcon from "@material-ui/icons/CardMembership";

// Components
import DialogComp from "../../../components/DialogComp";
import SelectComp from "../../../components/SelectComp";
import InputComp from "../../../components/InputComp";
import ButtonComp from "../../../components/ButtonComp";
import CheckComp from "../../../components/CheckComp";
import DatePickerComp from "../../../components/DatePickerComp";

// Modules
import {
  getTimeSlots,
  bookAppointment,
  bookAppointmentAction,
} from "../../modules/Appointment/AppointmentBoard/action";
import { showSnackbar } from "../../modules/Snackbar/action";

// Container
import PaymentCont from "../Payment/PaymentCont";

// Utils
import { teleCom } from "../../utils/constant";
import { formatDate } from "../../utils/helper";
import { Paper } from "@material-ui/core";
import { Redirect } from "react-router-dom";

const AddAppointmentCont = ({
  user,
  clinicDetails,
  timeSlots,
  getTimeSlots,
  bookAppointment,
}) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    clinicid: "",
    date: formatDate(new Date()),
    doctorid: "",
    isteleappoint: 0,
    patientemail: user && user.email,
    patientmobile: user && user.contactno,
    patientname: user && `${user.name} ${user.lname}`,
    reason: "",
    slot: "",
    teleappointmentchannel: "",
    appointmentfrom: "PWA",
  });

  const [isModalOpen, setModalState] = useState(false);

  const { bookedAppointment } = useSelector(
    (state) => state.Patient.appointmentBoard
  );

  useEffect(() => {
    if (bookedAppointment && bookedAppointment.status) {
      setFormData({
        clinicid: "",
        date: formatDate(new Date()),
        doctorid: "",
        isteleappoint: 0,
        patientemail: user && user.email,
        patientmobile: user && user.contactno,
        patientname: user && `${user.name} ${user.lname}`,
        reason: "",
        slot: "",
        teleappointmentchannel: "",
        appointmentfrom: "PWA",
      });
    }
  }, [bookedAppointment]);

  useEffect(() => {
    if (clinicDetails && Object.keys(clinicDetails).length) {
      const data = {
        clinicid: clinicDetails.clinic && clinicDetails.clinic.id,
        date: formatDate(new Date()),
        doctorId: clinicDetails.consultants && clinicDetails.consultants[0].id,
      };
      setFormData((formRecord) => ({
        ...formRecord,
        clinicid: clinicDetails.clinic.id,
        doctorid: clinicDetails.consultants[0].id,
      }));
      getTimeSlots(data);
    }
  }, [clinicDetails, getTimeSlots]);

  const handleChange = (event) => {
    var { name, value, type } = event.target;
    if (type === "checkbox") {
      value = event.target.checked ? 1 : 0;
    }
    if (name === "reason") {
      setFormData({
        ...formData,
        clinicid: clinicDetails.clinic.id,
        doctorid: clinicDetails.consultants[0].id,
      });
    }
    if (name === "date") {
      const data = {
        clinicid: clinicDetails.clinic && clinicDetails.clinic.id,
        date: value,
        doctorId: clinicDetails.consultants && clinicDetails.consultants[0].id,
      };
      getTimeSlots(data);
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (
      formData.date.toString().length &&
      formData.doctorid.toString().length &&
      formData.patientemail.toString().length &&
      formData.patientmobile.toString().length &&
      formData.patientname.toString().length &&
      formData.reason.toString().length &&
      formData.slot.toString().length
    ) {
      bookAppointment(formData);
    } else {
      return dispatch(
        showSnackbar({
          status: 0,
          message: "Please fill the required fields.",
        })
      );
    }
  };

  if (bookedAppointment && bookedAppointment.status) {
    dispatch(bookAppointmentAction(null));
    return <Redirect to="appointment" />;
  }

  return (
    <div style={{ marginBottom: "100px", marginTop: "80px" }}>
      <Paper>
        {clinicDetails && clinicDetails.consultants && (
          <div>
            <SelectComp
              options={clinicDetails.consultants.map((value) => {
                return {
                  label: value.name,
                  value: value.id,
                };
              })}
              selected={clinicDetails.consultants[0].id}
              handleChange={handleChange}
              label="Select Doctor*"
              name="doctorid"
            >
              <LocalHospitalIcon />
              {/* <img src={doctorSath} height="25px" width="25px" alt="Doctor name" /> */}
            </SelectComp>

            <DatePickerComp
              handleChange={handleChange}
              name="date"
              value={formData.date}
              isPastEnable={true}
              label="Select Date*"
            />
            <SelectComp
              options={
                timeSlots &&
                timeSlots.slots
                  .filter((value) => !value.isbooked)
                  .map((value) => {
                    return {
                      label: value.slot,
                      value: value.slot,
                    };
                  })
              }
              handleChange={handleChange}
              label="Select Slot*"
              name="slot"
            >
              <AddAlarmIcon />
            </SelectComp>
            <InputComp
              label="Patient Name*"
              name="patientname"
              type="text"
              onChange={handleChange}
              value={formData.patientname}
            >
              <PersonIcon />
            </InputComp>
            <InputComp
              label="Patient Email*"
              name="patientemail"
              type="text"
              onChange={handleChange}
              value={formData.patientemail}
            >
              <EmailIcon />
            </InputComp>
            <InputComp
              label="Patient Mobile*"
              name="patientmobile"
              type="text"
              onChange={handleChange}
              value={formData.patientmobile}
            >
              <PhoneAndroidIcon />
            </InputComp>
            <InputComp
              label="Appointment Reason*"
              name="reason"
              type="text"
              onChange={handleChange}
              value={formData.reason}
            >
              <AssignmentIcon />
            </InputComp>

            <CheckComp
              label="Tele Appointment"
              value={formData.isteleappoint}
              handleChange={handleChange}
              name="isteleappoint"
            />
            {formData.isteleappoint ? (
              <SelectComp
                options={teleCom.map((value) => {
                  return {
                    label: value,
                    value: value,
                  };
                })}
                handleChange={handleChange}
                label="Select channel"
                name="teleappointmentchannel"
              >
                <CallIcon />
              </SelectComp>
            ) : (
              ""
            )}
            {formData.teleappointmentchannel === "Skype" && (
              <InputComp
                label="Enter your skype Id"
                name="skypeId"
                type="text"
                onChange={handleChange}
                value={formData.skypeId}
              >
                <CardMembershipIcon />
              </InputComp>
            )}

            <br />
            <div className="d-flex justify-content-center flex-column align-items-center mb-5">
              {/* <ButtonComp label="Book Appointment" onClick={handleSubmit} /> */}
              <PaymentCont
                handleSubmit={handleSubmit}
                doctorid={formData.doctorid}
                formData={formData}
              />
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.Patient.login.user,
  clinicDetails: state.Patient.login.clinicDetails,
  timeSlots: state.Patient.appointmentBoard.timeSlots,
  bookedAppointment: state.Patient.appointmentBoard.bookedAppointment,
});

const mapDispatchToProps = {
  getTimeSlots,
  bookAppointment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAppointmentCont);
