import { PAYMENT_LOGS } from "./action";

const initialState = {
  paymentlogs: null,
};

export default function paymentLogReducder(state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case PAYMENT_LOGS:
      return {
        ...state,
        paymentlogs: data,
      };
    default:
      return state;
  }
}
