import React, { useState } from "react";
import { getTime, getDateOnly } from "../../Patient/utils/helper";
import pdfImage from "../../assets/images/pdf.png";
import { Dialog, Slide, makeStyles, Button } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
let date;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  imageModal: {
    "& .MuiDialog-paper": {
      background: "#000000b0",
      maxWidth: "500px",
    },
  },
  divModal: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  onCloseButton: {
    position: "absolute",
    top: "20px",
    right: "10px",
    color: "white",
    background: "#00000057",
  },
}));

const Message = ({
  message: { createtimestamp, fromuser, message, messagetype },
  currentUser,
}) => {
  const classes = useStyles();

  const [isOpenImage, setZoomImage] = useState(false);

  let isSentByCurrentUser = false;
  if (fromuser === currentUser.id.toString()) {
    isSentByCurrentUser = true;
  }

  const getUpdatedDate = () => {
    if (date !== getDateOnly(createtimestamp)) {
      date = getDateOnly(createtimestamp);
      return (
        <div className="updatedDate">
          <span>{date}</span>
        </div>
      );
    }
  };

  const Imageclick = () => {
    setZoomImage(!isOpenImage);
  };
  return (
    <>
      {getUpdatedDate()}
      {isSentByCurrentUser ? (
        <div className="messageContainer justifyEnd">
          <div className="messageBox backgroundBlue">
            {messagetype === "file" ? (
              message.endsWith("pdf") ? (
                <div className="d-flex">
                  <img
                    src={pdfImage}
                    height="25px"
                    aria-hidden="true"
                    alt="pdf"
                  />
                  <a className="pdfImage rightLink" href={message}>
                    {message}
                  </a>
                </div>
              ) : (
                <img
                  className="chatImages"
                  onClick={Imageclick}
                  src={message}
                  alt={message}
                />
              )
            ) : (
              <p className="messageText colorWhite">{message}</p>
            )}
            <div style={{ fontSize: "10px", textAlign: "right" }}>
              {getTime(createtimestamp)}
            </div>
          </div>
        </div>
      ) : (
        <div className="messageContainer justifyStart">
          <div className="messageBox backgroundLight">
            {messagetype === "file" ? (
              message.endsWith("pdf") ? (
                <div className="d-flex">
                  <img
                    src={pdfImage}
                    height="25px"
                    aria-hidden="true"
                    alt="pdf"
                  />
                  <a className="pdfImage" href={message}>
                    {message}
                  </a>
                </div>
              ) : (
                <img
                  onClick={Imageclick}
                  width="100%"
                  src={message}
                  alt={message}
                />
              )
            ) : (
              <p className="messageText colorDark">{message}</p>
            )}
            <div style={{ fontSize: "10px", color: "#353535" }}>
              {getTime(createtimestamp)}
            </div>
          </div>
        </div>
      )}
      <Dialog
        fullScreen
        open={isOpenImage}
        onClose={Imageclick}
        scroll="body"
        className={classes.imageModal}
        TransitionComponent={Transition}
      >
        <div className={classes.divModal}>
          <Button onClick={Imageclick} className={classes.onCloseButton}>
            <HighlightOffIcon />
          </Button>
          <img className="image" src={message} width="100%" alt="no" />
        </div>
      </Dialog>
    </>
  );
};

export default Message;
