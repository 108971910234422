import React from 'react';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FaceIcon from '@material-ui/icons/Face';
import VideoCallComp from '../VideoCallComp';

function SimpleDialog(props) {
    const { onClose, selectedValue, open, cutTheVideoCall, videoCallPayload, drName } = props;


    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{drName}</DialogTitle>
            <List>
                <ListItem >
                    <ListItemAvatar>
                        <Avatar >
                            <FaceIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Doctor is calling, please join the call" />
                </ListItem>
                <ListItem className="d-flex justify-content-between" >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <VideoCallComp videoCallPayload={videoCallPayload} cutTheVideoCall={cutTheVideoCall} isFromCallModal={true} />
                </ListItem>
            </List>
        </Dialog>
    );
}

export default function CallComp({ openModel, cutTheVideoCall, videoCallPayload, drName }) {
    const [open, setOpen] = React.useState(openModel);
    const [isJoined, manageCallJoining] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        cutTheVideoCall()
    };

    const manageCall = () => {
        setOpen(false);
        manageCallJoining(true)
    }

    return (
        <div>
            <SimpleDialog
                manageCallJoining={manageCall}
                open={open}
                onClose={handleClose}
                isJoined={isJoined}
                videoCallPayload={videoCallPayload}
                cutTheVideoCall={cutTheVideoCall}
                drName={drName} />
        </div>
    );
}
