import { globalApiHandler } from "../../utils/globalApiHandler";

import { activeOverlay, disableOverlay } from "../Overlay/action";
import { showSnackbar } from "../Snackbar/action";

export const PAYMENT_ORDER_IDS = "PAYMENT_ORDER_IDS";
export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";

export const getPaymentOrderidsAction = (data) => ({
  type: PAYMENT_ORDER_IDS,
  data: data,
});
const setSuccessPaymentAction = (data) => ({
  type: GET_PAYMENT_STATUS,
  data: data,
});

export const makePayment = (paymentDetails) => async (dispatch) => {
  dispatch(activeOverlay());
  try {
    var jsonpaymentDetails = JSON.stringify(paymentDetails);
    var url = `${process.env.REACT_APP_BASE_URL}/patient/kivihealthapigetpaymentorderids`;
    const res = await globalApiHandler(url, "post", jsonpaymentDetails);
    var data = res.result.rzOptions;
    // var rzp1 = new window.Razorpay(data);
    // rzp1.open();
    dispatch(getPaymentOrderidsAction(data));
    dispatch(disableOverlay());
  } catch (err) {
    dispatch(disableOverlay());
    console.log(err);
  }
};

export const setSuccessPayment = (successDetails) => async (dispatch) => {
  dispatch(activeOverlay());
  try {
    var jsonsuccessDetails = JSON.stringify(successDetails);
    var url = `${process.env.REACT_APP_BASE_URL}/patient/kivihealthapipostpaymentcallbacks`;
    const res = await globalApiHandler(url, "post", jsonsuccessDetails);
    const data = res.result;
    dispatch(showSnackbar(data.orderStatus));
    dispatch(setSuccessPaymentAction(data));
    dispatch(disableOverlay());
  } catch (err) {
    dispatch(disableOverlay());
    console.log(err);
  }
};
