import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Paper, makeStyles, Divider } from "@material-ui/core";

import ButtonComp from "../../../components/ButtonComp";
import {
  makePayment,
  setSuccessPayment,
  getPaymentOrderidsAction,
} from "../../modules/Payment/action";
import { showSnackbar } from "../../modules/Snackbar/action";
import { bookAppointment } from "../../modules/Appointment/AppointmentBoard/action";

const useStyles = makeStyles((theme) => ({
  rootPaper: {
    width: "95%",
    padding: "1rem",
    boxShadow: "0px 0px 10px #666",
    marginBottom: "15px",
  },
  rupees: {
    color: "green",
    fontSize: "18px",
  },
}));

const PaymentCont = ({ handleSubmit, doctorid, formData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isPayDisable, setPaymentVisibility] = useState(false);

  const { clinicDetails } = useSelector((state) => state.Patient.login);
  const { paymentOrderids } = useSelector((state) => state.Patient.payment);
  const { appointmentList } = useSelector(
    (state) => state.Patient.appointmentBoard
  );

  const handlePayment = (e) => {
    if (
      formData.date.toString().length &&
      formData.doctorid.toString().length &&
      formData.patientemail.toString().length &&
      formData.patientmobile.toString().length &&
      formData.patientname.toString().length &&
      formData.reason.toString().length &&
      formData.slot.toString().length
    ) {
      var amount =
        appointmentList.totalappointments > 0
          ? clinicDetails.clinic.followupcharge
          : clinicDetails.clinic.consultation;
      const payload = {
        clinicid: clinicDetails && clinicDetails.clinic.id,
        doctorid: doctorid,
        amount: amount,
        requestfrom: "pwa",
      };
      setPaymentVisibility(true);
      dispatch(makePayment(payload));
    } else {
      return dispatch(
        showSnackbar({
          status: 0,
          message: "Please fill the required fields.",
        })
      );
    }
    e.preventDefault();
  };
  useEffect(() => {
    async function fecthData() {
      if (paymentOrderids) {
        paymentOrderids.modal = {
          ondismiss: (data) => {
            dispatch(
              showSnackbar({
                status: 0,
                message:
                  "Error occured while processing your request, please try again!",
              })
            );
            setPaymentVisibility(false);
            dispatch(getPaymentOrderidsAction(null));
          },
        };
        paymentOrderids.handler = (successData) => {
          if (successData.razorpay_payment_id) {
            const payload = {
              clinicid: clinicDetails && clinicDetails.clinic.id,
              doctorid: doctorid,
              paymentrequestid:
                paymentOrderids && paymentOrderids.notes.paymentid,
              razorpay_payment_id: successData.razorpay_payment_id,
            };
            setPaymentVisibility(false);
            dispatch(setSuccessPayment(payload));
            dispatch(bookAppointment(formData));
          }
        };
        var rzp1 = new window.Razorpay(paymentOrderids);
        rzp1.open();
      }
    }
    fecthData();
  }, [paymentOrderids]);

  return (
    <>
      <Divider variant="inset" component="li" />
      <Paper className={classes.rootPaper}>
        <div className="d-flex justify-content-between ">
          <b>Follow up Charges: </b>
          <p className={classes.rupees}>
            ₹ {clinicDetails && clinicDetails.clinic.followupcharge}
          </p>
        </div>
        <div className="d-flex justify-content-between ">
          <b>Convinience Fees: </b>
          <p className={classes.rupees}>₹ 10 + Card Fees*</p>
        </div>
      </Paper>
      {clinicDetails && clinicDetails.clinic.offlinepayment ? (
        <ButtonComp label="Book Appointment" onClick={handleSubmit} />
      ) : (
        <></>
      )}
      {clinicDetails && clinicDetails.clinic.advancepayment ? (
        <ButtonComp
          isDisable={isPayDisable}
          label="Pay and Book Appointment"
          onClick={(event) => handlePayment(event)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default PaymentCont;
