import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    margin: {
        // margin: theme.spacing(1),
        width: "100%"
    },
    flexGrow: {
        flexGrow: 1
    },
    Icon: {
        color: "#26a0c9",
        marginRight: 5
    }
}));

export default function InputComp(props) {
    const classes = useStyles();
    return (
        <div className={classes.margin}>
            <Grid container alignItems="flex-end" className="p-2" >
                <Grid item className={classes.Icon}>
                    {props.children}
                </Grid>
                <Grid item className={classes.flexGrow}>
                    <FormControl className={classes.margin} disabled={props.isDisable ? props.isDisable : false}>
                        <InputLabel htmlFor={`kivi_input_${props.name}`}>{props.label}</InputLabel>
                        <Input
                            id={`kivi_input_${props.name}`}
                            type={props.type}
                            value={props.value}
                            onChange={props.onChange}
                            name={props.name}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}
