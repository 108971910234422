import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import EnableNotificationCheckComp from "../EnableNotificationCheckComp";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontWeight: "700",
  },
  appbar: {
    background: "#ffffff",
    maxWidth: "500px",
    "&.MuiAppBar-positionFixed": {
      right: "auto",
    },
  },
}));

export default function PatientHeaderComp(props) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="default" className={classes.appbar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {props.heading}
        </Typography>
        <EnableNotificationCheckComp sytle={{alignSelf : "flex-end"}} />
      </Toolbar>
    </AppBar>
  );
}
