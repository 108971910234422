import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Slide from "@material-ui/core/Slide";

import FloatingBtnComp from "../FloatingBtnComp";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "#ffffff",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#444",
    fontWeight: "700",
  },
  mainDialog: {
    background: "#efeeec",
    maxWidth: "500px !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogComp(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(props.isModalOpen ? props.isModalOpen : false);

  const handleClickOpen = () => {
    // setOpen(true);
    props.setModalState(true);
  };

  const handleClose = () => {
    props.setModalState(false);
    // setOpen(false);
  };

  return (
    <div>
      <FloatingBtnComp onClick={handleClickOpen} module={props.module} />
      <Dialog
        fullScreen
        open={props.isModalOpen}
        onClose={handleClose}
        classes={{ paper: classes.mainDialog }}
        scroll="body"
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" onClick={handleClose} aria-label="close">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.heading}
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper className="mt-3" elevation={1}>
          {props.children}
        </Paper>
      </Dialog>
    </div>
  );
}
