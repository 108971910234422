import React from 'react';

import EditProfileCont from '../../../containers/MyAccount/EditProfileCont';
import PatientHeaderComp from '../../../../components/PatientHeaderComp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

function EditProfile() {

    return (
        <>
            <PatientHeaderComp heading={
                <div className="d-flex">
                    <Link to="/myaccount" style={{ marginRight: 'auto', color: "#444" }}><ArrowBackIcon /></Link>
                    <span style={{ flex: 1 }}>View Profile</span>
                </div>
            } />
            <EditProfileCont />
        </>
    );
}

export default EditProfile;
