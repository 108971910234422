
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR"
export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS"

export const showSnackbar = message => {
    return dispatch => {
        dispatch({ type: SNACKBAR_SUCCESS, data: message });
    };
};

export const clearSnackbar = () => {
    return dispatch => {
        dispatch({ type: SNACKBAR_CLEAR });
    };
};