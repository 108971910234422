import React from "react";
import { connect, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import { getAppointmentList } from "../../modules/Appointment/AppointmentBoard/action";
import Avatar from "@material-ui/core/Avatar";
import FaceIcon from "@material-ui/icons/Face";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    margin: "auto",
    cursor: "pointer",
    marginTop: "2rem",
    borderRadius: "5px",
  },
  smallText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  headerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
}));

const UserProfileCont = () => {
  const classes = useStyles();

  const { user } = useSelector((state) => state.Patient.login);
  return (
    user && (
      <div className="p-2 text-left d-flex align-items-center">
        <Avatar src={user.profilepic} className="mr-2">
          <FaceIcon />
        </Avatar>
        <div className={classes.headerStyle}>
          <div>
            <div
              style={{ fontSize: "18px" }}
            >{`${user.name} ${user.lname}`}</div>
            <div className={classes.smallText}>{`${user.email}`}</div>
            <div className={classes.smallText}>{`${user.contactno}`}</div>
          </div>
          {true && (
            <div className={classes.smallText}>
              <Link to="/editProfile">
                View <EditIcon className={classes.smallText} />
              </Link>
            </div>
          )}
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.Patient.appointmentBoard.appointmentList,
  bookedAppointment: state.Patient.appointmentBoard.bookedAppointment,
});

const mapDispatchToProps = {
  getAppointmentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileCont);
