import React from 'react'
import { connect } from 'react-redux';

import OverlayComp from '../../../components/OverlayComp';


const OverlayCont = ({ isOverlay }) => {
    return (
        <OverlayComp
            open={isOverlay}
        />
    )
}

const mapStateToProps = state => {
    return {
        isOverlay: state.Patient.overlay.isOverlay
    }
}

export default connect(mapStateToProps, {})(OverlayCont)