import {
  USER_LOGIN_SUCCESS,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_ERROR,
  USER_LOGOUT_SUCCESS,
  USER_LOGIN_FAILURE,
  GET_USER_DETAILS_SUCCESS_FAIL,
  GET_CLINIC_DETAILS,
  FORGOT_PASSWORD,
} from "./action";

const userDefault = {
  isLoggedIn: false,
  userVerified: false,
  signupVerified: false,
  token: null,
  user: null,
  clinicDetails: null,
  forgotPassword: null,
};

export default function loginReducer(state = userDefault, action) {
  const { type, data } = action;
  switch (type) {
    case USER_VERIFY_SUCCESS:
      return {
        ...state,
        token: data,
        userVerified: true,
      };
    case USER_LOGOUT_SUCCESS:
    case USER_VERIFY_ERROR:
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        userVerified: true,
        token: null,
        clinicDetails: {},
        user: {},
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token: data,
      };
    case GET_USER_DETAILS_SUCCESS_FAIL:
      return {
        ...state,
        isLoggedIn: true,
        user: data,
      };
    case GET_CLINIC_DETAILS:
      return {
        ...state,
        clinicDetails: data,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: data,
      };
    default:
      return state;
  }
}
