
import {
    GET_APPOINTMENT,
    GET_TIME_SLOTS,
    BOOK_APPOINTMENT
} from './action';

const initialState = {
    appointmentList: null,
    timeSlots: null,
    bookedAppointment: null
}

export default function appointmentBoardReducder(state = initialState, action) {
    const { type, data } = action;
    switch (type) {
        case GET_APPOINTMENT:
            return {
                ...state,
                appointmentList: data,
            };
        case GET_TIME_SLOTS:
            return {
                ...state,
                timeSlots: data,
            };
        case BOOK_APPOINTMENT:
            return {
                ...state,
                bookedAppointment: data,
            };
        default:
            return state;
    }
}