import React from "react";

import { Divider, makeStyles, Slide } from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  secondaryMessage: {
    display: "flex",
    justifyContent: "space-between",
  },
  secondaryMsgText: {
    maxWidth: "50%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  link: {
    color: "black",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
}));

const DoctorsListCont = () => {
  const classes = useStyles();

  const { clinicDetails } = useSelector((state) => state.Patient.login);

  return (
    <Slide direction="up" in={true}>
      <div style={{ marginTop: "70px" }}>
        {clinicDetails &&
          clinicDetails.consultants &&
          clinicDetails.consultants.map((doctor, index) => {
            return (
              <List
                className={classes.link}
                component={Link}
                to={{
                  pathname: `/chatbox/${doctor.id}`,
                  doctor: doctor,
                  module: "doctorList",
                }}
                key={index}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={doctor.profilepic}>
                      <FaceIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={doctor.name} />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            );
          })}
      </div>
    </Slide>
  );
};

export default DoctorsListCont;
