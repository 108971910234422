import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import CallComp from "../../../components/CallComp/inex";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { createBrowserHistory } from "history";
import { setVideoPayload } from "../../modules/Socket/action";

const history = createBrowserHistory();

const CallModalCont = ({ apisocket, access_token, videoCallPayload }) => {
  const [payloadData, setPayloadData] = useState(null);
  const [drName, setDrName] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fecthData() {
      var getQueryParams = await queryString.parse(location.search);

      if (getQueryParams && Object.keys(getQueryParams).length) {
        if (getQueryParams.videoCallPaylpad === null) {
        }
        localStorage.setItem("videoCall", JSON.stringify(getQueryParams));
        setPayloadData(getQueryParams);
        dispatch(setVideoPayload(getQueryParams));
      }
    }
    fecthData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    let videoCallPayloadData = videoCallPayload
      ? videoCallPayload
      : JSON.parse(localStorage.getItem("videoCall"));
    if (videoCallPayloadData && videoCallPayloadData.doctor) {
      setDrName(videoCallPayloadData.doctor.name);
    }
    setPayloadData(videoCallPayloadData);
  }, [videoCallPayload]);

  const cutTheVideoCall = () => {
    setPayloadData(null);
    localStorage.removeItem("videoCall");
    if (payloadData && apisocket) {
      var payload = {
        from: "patient",
        kivipatientid: payloadData.kivipatientid,
        netmedspatientid: null,
        consultationid: null,
        clinicid: payloadData.clinicid,
        doctorid: payloadData.doctorid,
        sessionid: payloadData.sessionid,
        access_token: access_token,
      };
      payload = JSON.stringify(payload);
      apisocket.emit("disconnectVideoCall", { data: payload });
      history.push("/chat");
    }
  };

  return payloadData ? (
    <CallComp
      videoCallPayload={payloadData}
      openModel={true}
      cutTheVideoCall={cutTheVideoCall}
      drName={drName}
    />
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  apisocket: state.Patient.socketCon.apiSocket,
  access_token: state.Patient.login.token,
  videoCallPayload: state.Patient.socketCon.videoCallPayload,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CallModalCont);
