


import React, { Profiler } from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';

// pages for this product
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import rootReducer from './rootReducer';
import App from './App';

import * as serviceWorker from './serviceWorker';
import PushNotifications from "./Patient/containers/PushNotifications/PushNotifications";

const initialState = {};
const middleware = [thunk];

export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
);

const logProfile = () => { }

ReactDOM.render(
  <Provider store={store}>
    <PushNotifications />
    <Profiler id="main" onRender={logProfile}>
      <App />
    </Profiler>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
