import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Slide from "@material-ui/core/Slide";
import FaceIcon from "@material-ui/icons/Face";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Divider } from "@material-ui/core";
import ChatInputBoxComp from "../ChatInputBoxComp";
import ChatMessageComp from "../ChatMessageComp";
import CameraComp from "../CameraComp";
import VideoCallComp from "../VideoCallComp";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  appBar: {
    position: "fixed",
    background: "#26a0c9",
    maxWidth: "500px",
    right: "auto",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#fff",
    whiteSpace: "nowrap",
  },
  mainDialog: {
    background: "#efeeec",
    maxWidth: "500px !important",
  },
  cameraOpen: {
    "& .MuiDialog-paper": {
      maxWidth: "100% !important",
    },
  },
  secondaryMessage: {
    display: "flex",
    justifyContent: "space-between",
  },
  secondaryMsgText: {
    maxWidth: "50%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  cameraStyle: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  videoHead: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#fff",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChatModalComp(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isCameraOpen, setCameraState] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);

  const videoCallPayload = props.videoCallPayload;

  const handleClickOpen = (e) => {
    setOpen(true);
    props.openChat(e, props.user);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const setCameraOn = () => {
    setCameraState(true);
  };
  const setCameraOff = () => {
    setCameraState(false);
  };

  const inputBoxButton = () => {
    return (
      <ChatInputBoxComp
        manageCameraState={setCameraOn}
        sendMessage={props.sendMessage}
        drDetails={props.user}
      />
    );
  };
  const messageHistoryBox = () => {
    return (
      <ChatMessageComp
        messages={messageHistory}
        currentUser={props.user}
        isActiveOverlay={props.isActiveOverlay}
      />
    );
  };

  const isVideoPayload = async () => {
    var aptDoctorId = props.user.id;
    var aptClinicId = props.clinicDetails.clinic.id;

    var videoCallPayloadData = JSON.parse(localStorage.getItem("videoCall"));

    if (
      videoCallPayloadData &&
      videoCallPayloadData.doctorid === aptDoctorId &&
      videoCallPayloadData.clinicid === aptClinicId
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <List className={classes.root} onClick={handleClickOpen}>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={props.user.profilepic}>
              <FaceIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={props.user.name}
            secondary={
              props.latestMessage ? (
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.secondaryMessage}
                  >
                    <span className={classes.secondaryMsgText}>
                      {props.latestMessageType !== "text"
                        ? props.latestMessageType
                        : props.latestMessage}
                    </span>
                    <span>{props.timestamp}</span>
                  </Typography>
                </>
              ) : null
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.mainDialog }}
        scroll="body"
        className={isCameraOpen ? classes.cameraOpen : ""}
        TransitionComponent={Transition}
      >
        {isCameraOpen && (
          <div className={classes.cameraStyle}>
            <CameraComp
              drDetails={props.user}
              sendMessage={props.sendMessage}
              manageCameraState={setCameraOff}
            />
          </div>
        )}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" onClick={handleClose} aria-label="close">
              <ArrowBackIcon />
            </IconButton>
            <Avatar src={props.user.profilepic}>
              <FaceIcon />
            </Avatar>
            <Typography variant="h6" className={classes.title}>
              {props.user.name}
            </Typography>
            <Typography variant="h6" className={classes.videoHead}>
              {isVideoPayload() === true && (
                <VideoCallComp
                  videoCallPayload={videoCallPayload}
                  cutTheVideoCall={props.cutTheVideoCall}
                />
              )}
            </Typography>
          </Toolbar>
        </AppBar>
        {messageHistoryBox()}
        {inputBoxButton()}
      </Dialog>
    </div>
  );
}
