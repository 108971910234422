import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import TermsConditions from "../TermsConditions";
import PatientFooterCont from "../../containers/Common/PatientFooterCont";
import CallModalCont from "../../containers/Common/CallModalCont";
import Socket from "../../containers/Socket/Socket";

const Layout = (props) => {
  const route = props.route;
  const Component = props.component;
  const isAcceptedTermsConditons = props.isAcceptedTermsConditons;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {isAcceptedTermsConditons && !isAcceptedTermsConditons.status ? (
        <>
          <TermsConditions />
        </>
      ) : (
        <>
          <main className="mb-5 mt-5">
            <Component route={route} />
          </main>
          <PatientFooterCont />
          <CallModalCont />
        </>
      )}
      {!props.apiSocket && <Socket />}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAcceptedTermsConditons:
    state.Patient.termsConditon.isAcceptedTermsConditons,
  apiSocket: state.Patient.termsConditon.socketCon,
});

export default connect(mapStateToProps, {})(Layout);
