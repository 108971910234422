import React from "react";
import { Link } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import DoctorsListCont from "../../../containers/Chat/DoctorsListCont";
import PatientHeaderComp from "../../../../components/PatientHeaderComp";

function DoctorsList() {
  return (
    <>
      <PatientHeaderComp
        heading={
          <div className="d-flex">
            <Link to="/chat" style={{ marginRight: "auto", color: "#444" }}>
              <ArrowBackIcon />
            </Link>
            <span style={{ flex: 1 }}>Doctors</span>
          </div>
        }
      />
      <DoctorsListCont />
    </>
  );
}

export default DoctorsList;
