
import {
    SET_TERMS_CONDITIONS,
    IS_ACCEPTED_TERMS_CONDITIONS
} from './action';

const initialState = {
    // termsConditions: null,
    isAcceptedTermsConditons: null
}

export default function termsConditionsReducer(state = initialState, action) {
    const { type, data } = action;
    switch (type) {
        case SET_TERMS_CONDITIONS:
            return {
                ...state,
                isAcceptedTermsConditons: data,
            };
        case IS_ACCEPTED_TERMS_CONDITIONS:
            return {
                ...state,
                isAcceptedTermsConditons: data,
            };
        default:
            return state;
    }
}