import axios from "axios";
import { globalApiHandler } from "../../utils/globalApiHandler";
import { BASE_URL, PUBLIC_TOKEN } from "../../utils/apiHandler";
// import { disableOverlay, activeOverlay } from "../Overlay/action";
// import { showSnackbar } from "../Snackbar/action";

export const SET_PATIENT_FIREBASE_ID = "SET_PATIENT_FIREBASE_ID";
export const GET_FIREBASE_ADMIN_DATA = "GET_FIREBASE_ADMIN_DATA";

const setPatientFirebaseIdAction = (data) => ({
  type: SET_PATIENT_FIREBASE_ID,
  data: data,
});
const getFirebaseAdminDataAction = (data) => ({
  type: GET_FIREBASE_ADMIN_DATA,
  data: data,
});

export const setPatientFirebaseId = (notificationToken) => async (dispatch) => {
  try {
    var data = JSON.stringify({ webdeviceid: notificationToken });
    const url = `${BASE_URL}/updatepatientfirebaseid`;

    const res = await globalApiHandler(url, "post", data);
    // dispatch(showSnackbar(res));
    dispatch(setPatientFirebaseIdAction(res));
  } catch (err) {}
};

export const getFirebaseAdminData = () => async (dispatch) => {
  try {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/commonapi/getfirebaseadmindata`,
      headers: {
        Authorization: PUBLIC_TOKEN,
      },
    };

    axios(config)
      .then(function (res) {
        if (res.status) {
          var data = res.data.result;
          dispatch(getFirebaseAdminDataAction(data));
        }
      })
      .catch(function (error) {});
  } catch (err) {}
};
