import Login from "../views/Auth/Login";
import ForgotPwd from "../views/Auth/ForgotPwd";
import Signup from "../views/Auth/Signup";
import SetPassword from "../views/Auth/SetPassword";
import RegisterUser from "../views/Auth/RegisterUser";

export default {
    Login: {
        component: Login,
        path: "/login"
    },
    ForgotPwd: {
        component: ForgotPwd,
        path: "/forgotPasword"
    },
    Signup: {
        component: Signup,
        path: "/signup"
    },
    SetPassword: {
        component: SetPassword,
        path: "/setPassword"
    },
    RegisterUser: {
        component: RegisterUser,
        path: "/gegisterUser"
    }
};