import Timeline from "../views/Timeline";
import Chat from "../views/Chat";
import Appointments from "../views/Appointments";
import Account from "../views/Account";
import EditProfile from "../views/Account/components/EditProfile";
import PaymentLog from "../views/Account/components/PaymentLog";
import ChangePassword from "../views/Account/components/ChangePassword";
import AddFile from "../views/Timeline/components/AddFile";
import DoctorsList from "../views/Chat/components/DoctorsList";
import ChatWindow from "../views/Chat/components/ChatWindow";
import AddAppointment from "../views/Appointments/components/AddAppointment";

export default {
  Timeline: {
    component: Timeline,
    path: "/timeline",
  },
  Chat: {
    component: Chat,
    path: "/chat",
  },
  DoctorsList: {
    component: DoctorsList,
    path: "/doctorsList",
  },
  ChatWindow: {
    component: ChatWindow,
    path: "/chatbox/:doctorId",
  },
  Appointments: {
    component: Appointments,
    path: "/appointment",
  },
  Account: {
    component: Account,
    path: "/myaccount",
  },
  EditProfile: {
    component: EditProfile,
    path: "/editProfile",
  },
  PaymentLog: {
    component: PaymentLog,
    path: "/paymentLog",
  },
  ChangePassword: {
    component: ChangePassword,
    path: "/changePassword",
  },
  AddAppointment: {
    component: AddAppointment,
    path: "/bookAppointment",
  },
  AddFile: {
    component: AddFile,
    path: "/timeline/addfile",
  },
};
