import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AlreadyChatListCont from "./AlreadyChatListCont";
import FloatingBtnComp from "../../../components/FloatingBtnComp";

const NewChatCont = ({ clinicDetails, access_token, apiSocket }) => {
  const receiveAllChats = async () => {
    var payloadforFetch = {
      clinicid: clinicDetails.clinic.id,
      access_token: access_token,
    };
    payloadforFetch = JSON.stringify(payloadforFetch);
    if (apiSocket) {
      apiSocket.emit("fetchPatientsConversion", { data: payloadforFetch });
    }
  };
  useEffect(() => {
    if (apiSocket && access_token && clinicDetails && clinicDetails.clinic) {
      receiveAllChats();
    }
  }, [clinicDetails, access_token, apiSocket]);

  return (
    <div style={{ paddingTop: "30px" }}>
      <AlreadyChatListCont />
      <Link to="/doctorsList">
        <FloatingBtnComp module="chat" />
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinicDetails: state.Patient.login.clinicDetails,
  user: state.Patient.login.user,
  access_token: state.Patient.login.token,
  videoCallPayload: state.Patient.socketCon.videoCallPayload,
  apiSocket: state.Patient.socketCon.apiSocket,
  prevChatDataList: state.Patient.socketCon.prevChatDataList,
  setChatHistory: state.Patient.socketCon.setChatHistory,
  newMessage: state.Patient.socketCon.newMessage,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewChatCont);
