
import {
    ACTIVE_DISABLE_OVERLAY
} from './action';

const initialState = {
    isOverlay: false
}
export default function overlayReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIVE_DISABLE_OVERLAY:
            return Object.assign({}, state, {
                isOverlay: action.data,
            });
        default:
            return state;
    }
}