import { BASE_URL } from "../../utils/apiHandler";
import { globalApiHandler } from "../../utils/globalApiHandler";

import { activeOverlay, disableOverlay } from "../Overlay/action";

export const API_SOCKET = "API_SOCKET";
export const VIDEO_CALL_PAYLOAD = "VIDEO_CALL_PAYLOAD";
export const CALL_DISCONNECTED = "CALL_DISCONNECTED";
export const SET_PREV_CHAT_DATA = "SET_PREV_CHAT_DATA";
export const SET_CHAT_HISTORY = "SET_CHAT_HISTORY";
export const NEW_MESSAGE_RECIEVED = "NEW_MESSAGE_RECIEVED";

const setSocketConnectionAction = (data) => ({
  type: API_SOCKET,
  data: data,
});
const setVideoPayloadAction = (data) => ({
  type: VIDEO_CALL_PAYLOAD,
  data: data,
});
const callDisconnectedAction = (data) => ({
  type: CALL_DISCONNECTED,
  data: data,
});
const setPrevChatDataAction = (data) => ({
  type: SET_PREV_CHAT_DATA,
  data: data,
});
const setChatHistoryAction = (data) => ({
  type: SET_CHAT_HISTORY,
  data: data,
});
const newMessageRecievedAction = (data) => ({
  type: NEW_MESSAGE_RECIEVED,
  data: data,
});

// const callDisconnectedAction = (data) => ({
//   type: CALL_DISCONNECTED,
//   data: data,
// });

export const setSocketConnection = (apiSocket) => async (dispatch) => {
  dispatch(activeOverlay());
  dispatch(setSocketConnectionAction(apiSocket));
  dispatch(disableOverlay());
};

export const setVideoPayload = (payload) => async (dispatch) => {
  await dispatch(activeOverlay());
  await dispatch(setVideoPayloadAction(payload));
  await dispatch(disableOverlay());
};

export const setPrevChatData = (payload) => async (dispatch) => {
  await dispatch(activeOverlay());
  await dispatch(setPrevChatDataAction(payload));
  await dispatch(disableOverlay());
};
export const setUserChatHistory = (payload) => async (dispatch) => {
  await dispatch(activeOverlay());
  await dispatch(setChatHistoryAction(payload));
  await dispatch(disableOverlay());
};
export const newMessageRecieved = (payload) => async (dispatch) => {
  await dispatch(activeOverlay());
  await dispatch(newMessageRecievedAction(payload));
  await dispatch(disableOverlay());
};

export const addCallDuration = (durationDetail) => async (dispatch) => {
  dispatch(activeOverlay());
  var data = JSON.stringify(durationDetail);
  const url = `${BASE_URL}/addtelecalllog`;

  try {
    const res = await globalApiHandler(url, "post", data);
    dispatch(callDisconnectedAction(res));
    dispatch(disableOverlay());
  } catch (err) {}
};
