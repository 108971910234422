export function convertTimeStamp(value) {
  // Unixtimestamp
  var unixtimestamp = value;
  // Convert timestamp to milliseconds
  var date = new Date(unixtimestamp * 1000);
  return formatDateCommon(date);
}

export function formatStartDatetime(value) {
  var date = new Date(value);
  return formatDateCommon(date);
}

export function getTime(value) {
  var unixtimestamp = value;
  // Convert timestamp to milliseconds
  var date = new Date(unixtimestamp * 1000);
  var hours = date.getHours();

  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  var minutes = "0" + date.getMinutes();

  return `${hours}:${minutes.substr(-2)} ${ampm}`;
}

export function getDateOnly(value) {
  var unixtimestamp = value;
  // Convert timestamp to milliseconds
  var date = new Date(unixtimestamp * 1000);
  var months_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Year
  var year = date.getFullYear();
  // Month
  var month = months_arr[date.getMonth()];
  // Day
  var day = date.getDate();

  return `${day}-${month}-${year}`;
}

function formatDateCommon(date) {
  // Months array
  var months_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Year
  var year = date.getFullYear();

  // Month
  var month = months_arr[date.getMonth()];

  // Day
  var day = date.getDate();

  // Hours
  var hours = date.getHours();

  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;

  hours = hours ? hours : 12;

  // Minutes
  var minutes = "0" + date.getMinutes();

  // Seconds
  // var seconds = "0" + date.getSeconds();

  // Display date time in MM-dd-yyyy h:m:s format
  var convdataTime =
    day +
    "-" +
    month +
    "-" +
    year +
    " " +
    hours +
    ":" +
    minutes.substr(-2) +
    " " +
    ampm;

  return convdataTime;
}

export function formatDate(date) {
  // Year
  var year = date.getFullYear();
  // Month
  var month = date.getMonth() + 1;
  month = String(month).padStart(2, "0");
  // Day
  var day = date.getDate();
  day = String(day).padStart(2, "0");

  // Display date time in MM-dd-yyyy h:m:s format
  var convdataTime = year + "-" + month + "-" + day;

  return convdataTime;
}

export function isIosSupported() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  var isSafari = !!navigator.userAgent.match(/Version\/[\d\. ]+.*Safari/);
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if ((isSafari && iOS) || (isSafari && /android/i.test(userAgent))) {
    return false;
  } else {
    return true;
  }
}
