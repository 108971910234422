import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import _ from "lodash";

import PublicRoutes from "../../routes/PublicRoutes";
import PrivateRoutes from "../../routes/PrivateRoutes";
import Layout from "./Layout";

import { verify } from "../../modules/Auth/Login/action";
import { isAcceptedTermsConditons } from "../../modules/TermsConditions/action";
import OverlayCont from "../../containers/Overlay/OverlayCont";
import SnackbarComp from "../../../components/SnackbarComp";
import PublicLayout from "./PublicLayout";
import ClinicSplashComp from "../../../components/ClinicSplashComp";

const PatientApp = ({
  verify,
  userVerified,
  isLogged,
  isAcceptedTermsConditons,
  clinicDetails,
  ...rest
}) => {
  useEffect(() => {
    verify();
  }, [verify]);
  useMemo(() => {
    if (isLogged) {
      isAcceptedTermsConditons();
    }
  }, [isAcceptedTermsConditons, isLogged]);

  return !userVerified ? (
    <div>Loading...</div>
  ) : (
    <>
      <ClinicSplashComp clinicDetails={clinicDetails} />
      <SnackbarComp />
      <OverlayCont />
      <BrowserRouter>
        <Switch>
          {_.map(PublicRoutes, (route, key) => {
            const { component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={key}
                render={(route) => {
                  if (isLogged) return <Redirect push to="/timeline" />;
                  else {
                    return <PublicLayout component={component} route={route} />;
                  }
                }}
              />
            );
          })}
          {_.map(PrivateRoutes, (route, key) => {
            const { component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={key}
                render={(route) => {
                  if (isLogged)
                    return <Layout component={component} route={route} />;
                  else return <Redirect push to="/login" />;
                }}
              />
            );
          })}
          <Redirect push to="/login" />
        </Switch>
      </BrowserRouter>
    </>
  );
};
const mapStateToProps = (state) => ({
  isLogged: state.Patient.login.isLoggedIn,
  userVerified: state.Patient.login.userVerified,
  clinicDetails: state.Patient.login.clinicDetails,
});

export default connect(mapStateToProps, {
  verify,
  isAcceptedTermsConditons,
})(PatientApp);
